import React, { useEffect, useState } from "react";

import { Image } from "primereact/image";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Paginator } from 'primereact/paginator';
import { InputTextarea } from 'primereact/inputtextarea';

import { ContentStatus } from "@/data/types";
import { formatDate } from "@/utils/globals/date-utils";
import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';
import PreviewDialog from '@/react_app/components/Dialogs/PreviewDialog';

import { useToastModule } from '@/modules';
import { useUgcManager } from '@/modules/ugc/ugc-manager-module';

import noImage from "../../img/no-image.png";
import { ExpandArrowUp, ExpandArrowDown, CloseIcon } from "../img/Icons";

import "./NewPendingVideos.css";

const statusTabs = [
  { value: 'Pending', type: ContentStatus.Pending },
  { value: 'Approved', type: ContentStatus.Accepted },
  { value: 'Rejected', type: ContentStatus.Rejected },
];

const dateBodyTemplate = (data) => {
  return (
    <div className={'defaultTextTemplate'}>
      {formatDate(data.createdAt)}
    </div>
  );
};

const statusPendingBodyTemplate = () => {
  return (
    <div className={'statusTemplate statusPending'}>
      Pending
    </div>
  );
};

const statusApprovedBodyTemplate = () => {
  return (
    <div className={'statusTemplate statusApproved'}>
      Approved
    </div>
  );
};

const statusRejectedBodyTemplate = () => {
  return (
    <div className={'statusTemplate statusRejected'}>
      Rejected
    </div>
  );
};

const authorTypeBodyTemplate = (data) => {
  return (
    <div className={'defaultTextTemplate'}>
      {(data.owner[0]?.type.charAt(0).toUpperCase() + data.owner[0]?.type.slice(1)) || data.owner?.type}
    </div>
  );
};

const authorBodyTemplate = (data) => {
  return (
    <div className={'authorTemplate'}>
      <Image src={data.owner[0]?.profile?.photo || data.owner?.profile?.photo || noImage} alt={'profile photo'} className={'authorTemplateImage'} />
      {data.owner[0]?.name || data.owner?.name}
    </div>
  );
};

const rowExpansionTemplate = (rowData) => {
  return (
    <div className={'expansionCommentTemplate'}>
      <div className={'darkLine'} />
      <p className={'rejectionLabel'}>Reason for rejection: </p>
      <p className={'rejectionText'}>{rowData.reasonReject}</p>
    </div>
  )
}

export default function (props) {
  const {
    resolveUGCLinksRequestsAction
  } = useUgcManager();
  const toast = useToastModule();
  const {
    loadingState, videos, changeStatus, limit, pageChange, search, getPendingVideosListReq
  } = props;
  const [activeTab, setActiveTab] = useState(ContentStatus.Pending);
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedRowsUGC, setExpandedRowsUGC] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [approveReason, setApproveReason] = useState('');
  const [rejectReason, setRejectReason] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [first, setFirst] = useState(0);
  const [time, setTime] = useState("");
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [previewDetails, setPreviewDetails] = useState("");


  const handleTabChange = (item) => {
    setActiveTab(item.type);
    changeStatus(item.type);
    setSearchValue('');
    setFirst(0);
  }

  const handleRejectVideo = () => {
    const isUGCRequest = selectedVideo?.campaignId?.paymentMethod === "UGC";
    const isUGCFinalReject = !!selectedVideo?.ugcFinalReject;
    if (isUGCRequest && !isUGCFinalReject) {
      handleResolveRequests('rejected', selectedVideo._id, {
        concept: selectedVideo.concept,
        ratio: selectedVideo.ratio,
        rejectReason: rejectReason
      })
    } else {
      props.rejectVideo(selectedVideo._id || selectedVideo.id, rejectReason).then(() => {
        setRejectReason('');
        setOpenRejectDialog(false);
      });
    }
  }

  const handleApproveVideo = () => {
    props.approveVideo(selectedVideo._id || selectedVideo.id, approveReason).then(() => {
      setApproveReason('');
      setOpenApproveDialog(false);
    });
  }

  const campaignBodyTemplate = (data) => {
    return (
      <div className={'underlinedTextTemplate'} onClick={() => props.goToCampaign(data.campaignId?.id)}>
        {data.campaignId?.name || data.campaignId[0]?.name}
      </div>
    );
  };

  const expanderTemplate = (rowData) => {
    return (
      <div className={'expanderTemplate'}>
        {expandedRows.includes(rowData) ?
          <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
            <ExpandArrowUp />
          </div> :
          <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
            <ExpandArrowDown />
          </div>
        }
      </div>
    )
  }
  const expanderTemplateUGC = (rowData) => {
    const isUGCRequest = rowData?.campaignId?.paymentMethod === "UGC";

    if (!isUGCRequest) {
      return null;
    }

    return (
      <div className='expanderTemplate'>
        {expandedRowsUGC.includes(rowData) ?
          <div onClick={() => setExpandedRowsUGC(prevState => prevState.filter(item => item !== rowData))}>
            <ExpandArrowUp />
          </div> :
          <div onClick={() => setExpandedRowsUGC(prevState => [...prevState, rowData])}>
            <ExpandArrowDown />
          </div>
        }
      </div>
    )
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    pageChange(event)
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        setFirst(0);
        search(e.target.value);
      }, 500)
    );
  }
  useEffect(() => {
    setNoResults(!(videos?.pendingVideos?.length))
  }, [videos]);

  const checkUGCLinksStatus = (data) => {
    const allPending = data?.every(concept =>
      concept.aspectRatios?.every(aspect => aspect.status === 'pending'));

    const allAccepted = data?.every(concept =>
      concept.aspectRatios?.every(aspect => aspect.status === 'accepted'));

    if (allPending) {
      return 'pending';
    } else if (allAccepted) {
      return 'accepted';
    }

    return false;
  };

  const handleResolveRequests = async (action, requestId, requestPayload) => {
    setIsActionLoading(true);
    await resolveUGCLinksRequestsAction(action, requestId, requestPayload).then(async (res) => {
      if (res) {
        setIsActionLoading(false);
        toast.showSuccess(`Requests ${action.includes('accept') ? "accepted" : "rejected"} successfully!`);
        if (action === "rejected") {
          setRejectReason('');
          setOpenRejectDialog(false);
        }
        if (action === "accepted" && checkUGCLinksStatus(res?.googleDriveLinks) === "accepted") {
          props.approveVideo(res.id, "").then(() => {
            setApproveReason('');
          });
        } else {
          await getPendingVideosListReq();
        }
      } else {
        setIsActionLoading(false);
        toast.showError("Something went wrong, please try again!");
      }
    }).catch((error) => {
      if (error) {
        setIsActionLoading(false);
        toast.showError(error.message || "Something went wrong, please try again!");
      }
    });
  };

  const titleBodyTemplate = (data) => {
    let applicantName = 'N/A'
    const isUGCRequest = data?.campaignId?.paymentMethod === "UGC";
    const channelId = data?.channelId;
    if (isUGCRequest && channelId) {
      applicantName = data?.owner[0]?.agencyWorkers.find(channel => channel?.channel?.channelId === channelId)?.channel?.title;
    }

    if (isUGCRequest) {
      return <div className='titleTemplate'>{channelId ? applicantName : 'N/A'}</div>
    }

    return (
      <>
        {data.vid ? <div className='titleTemplate' onClick={() => window.open(`https://www.youtube.com/watch?v=${data.vid}`, "_blank")}>
          <Image src={data.previewPhoto ? data?.previewPhoto : noImage} alt='thumbnail' className='titleTemplateImage' />
          {data.title}
        </div>
          :
          <div className='titleTemplate' onClick={() => window.open(data?.url, "_blank")}>
            <Image src={noImage} alt='thumbnail' className='titleTemplateImage' />
            <span className='videoUrl'>{data?.url}</span>
          </div>
        }
      </>
    );

  };

  const actionsBodyTemplate = (rowData) => {
    return (
      <div className={'actionsTemplate'}>
        <Button className={'approveButton'} onClick={() => { setOpenApproveDialog(true); setSelectedVideo(rowData) }}
        >Approve</Button>
        <Button className={'rejectButton'} onClick={() => {
          setOpenRejectDialog(true); setSelectedVideo({ ...rowData, ugcFinalReject: true })
        }}>Reject</Button>
      </div>
    )
  };

  const handlePreviewDialog = (flag, video) => {
    setPreviewDetails(video);
    setOpenPreviewDialog(flag);
  }
  const rowExpansionTemplateUGC = (rowData) => {
    const isUGCRequest = rowData?.campaignId?.paymentMethod === "UGC";
    if (!isUGCRequest) {
      return null;
    }
    return (
      <>
        <div className='darkLine' />
        <div className='expansionWrapper'>
          <div className='expansionDataSquare'>
            <div className='request-info type-name'>
              <div className={`request-reason ${activeTab}`}>
                <div className={'linksTemplate titleTemplate'}>
                  {rowData?.googleDriveLinks?.map((link, linkIndex) => (
                    <div key={linkIndex} className="linkItem">
                      <h3 className='defaultTextTemplate'>Concept {link.concept}</h3>
                      {link?.aspectRatios?.map((aspect, aspectIndex) => (
                        <div key={aspectIndex} className="aspectRatioItem">
                          <div className="linkWrap">
                            <span className="link ratio">
                              <span className='linkLabel'>Ratio: </span>
                              <span className='linkValue'>{aspect?.ratio || "16:9"}</span></span>
                            <span className={`link videoLink`} >
                              <span className='linkLabel'>Video URL: </span>&nbsp;
                              <video className='videoPreview' src={`${aspect?.videoUrl}#t=5`} controls={false} onClick={() => handlePreviewDialog(true, aspect)} />
                            </span>
                            <span className='link status'><span className='linkLabel'>Status: </span> <span className={`linkValue currentStatus ${aspect?.status}`}>{(aspect?.status === "accepted") ? "Approved" : aspect?.status}</span> </span>
                            {aspect?.status === "rejected" && <span className='link reason'><span className='linkLabel'>Reason: </span> <span className="linkValue">{aspect?.rejectReason ||
                              "N/A"}</span> </span>
                            }</div>
                          {aspect?.status === "pending" && <div className='expanderTemplate'>
                            <Button className='successIcon' icon="pi pi-check" severity="success" aria-label="Bookmark" tooltip='Approve'
                              disabled={isActionLoading || aspect?.status !== "pending"} onClick={() => {
                                handleResolveRequests('accepted', rowData._id, {
                                  concept: link.concept,
                                  ratio: aspect?.ratio
                                })
                              }} />
                            <Button className='rejectIcon' icon="pi pi-times" severity="danger" aria-label="Cancel" tooltip='Reject'
                              disabled={isActionLoading || aspect?.status !== "pending"} onClick={() => {
                                setOpenRejectDialog(true);
                                setSelectedVideo({
                                  concept: link.concept,
                                  ratio: aspect?.ratio,
                                  _id: rowData._id,
                                  campaignId: {
                                    ...rowData.campaignId
                                  }
                                });
                              }} />
                          </div>}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>

          </div >
        </div >
      </>
    );
  };

  return (
    <div className={'pending-videos'}>
      <p className={'title'}>Pending Videos YT</p>
      <div className={'tabsWrapper'}>
        <div className={'tabs'}>
          {statusTabs.map((item) =>
            <div key={item.type} className={item.type === activeTab ? 'tabSelected' : 'tabNormal'}
              onClick={() => handleTabChange(item)}>
              {item.value}
            </div>)}
        </div>
      </div>
      <span className="p-input-icon-left inputFieldWrapper">
        <i className="pi pi-search" />
        <InputText className={'inputField'} placeholder={'Search for video'} value={searchValue} onChange={(e) => handleSearch(e)} />
      </span>
      {activeTab === ContentStatus.Pending &&
        <DataTable value={videos?.pendingVideos} removableSort emptyMessage="No videos found" loading={loadingState}
          expandedRows={expandedRowsUGC}
          loadingIcon={
            <CustomLoader />
          }
          onRowToggle={(e) => setExpandedRowsUGC(e.data)}
          rowExpansionTemplate={rowExpansionTemplateUGC}
          style={{ minHeight: 500 }}>
          <Column field="createdAt" header="Date" body={dateBodyTemplate} sortable style={{ width: '10%' }}></Column>
          <Column field="title" header="Title" body={titleBodyTemplate} sortable style={{ width: '25%' }}></Column>
          <Column field="status" header="Status" body={statusPendingBodyTemplate} style={{ width: '10%' }}></Column>
          <Column field="campaignId.name" header="Campaign" body={campaignBodyTemplate} sortable style={{ width: '15%' }}></Column>
          <Column field="" header="Author type" body={authorTypeBodyTemplate} style={{ width: '12.5%' }}></Column>
          <Column field="owner[0].name" header="Author" body={authorBodyTemplate} sortable style={{ width: '15%' }}></Column>
          <Column field="" header={""} body={actionsBodyTemplate}></Column>
          <Column expander={true} body={expanderTemplateUGC} />
        </DataTable>
      }
      {activeTab === ContentStatus.Accepted &&
        <DataTable value={videos?.pendingVideos} removableSort emptyMessage="No videos found" loading={loadingState}
          loadingIcon={
            <CustomLoader />
          }
          style={{ minHeight: 500 }}>
          <Column field="createdAt" header="Date" body={dateBodyTemplate} sortable style={{ width: '10%' }}></Column>
          <Column field="title" header="Title" body={titleBodyTemplate} sortable style={{ width: '25%' }}></Column>
          <Column field="status" header="Status" body={statusApprovedBodyTemplate} style={{ width: '15%' }}></Column>
          <Column field="campaignId.name" header="Campaign" body={campaignBodyTemplate} sortable style={{ width: '17.5%' }}></Column>
          <Column field="" header="Author type" body={authorTypeBodyTemplate} style={{ width: '15%' }}></Column>
          <Column field="owner[0].name" header="Author" body={authorBodyTemplate} sortable style={{ width: '17.5%' }}></Column>
        </DataTable>
      }
      {activeTab === ContentStatus.Rejected &&
        <DataTable value={videos?.pendingVideos} removableSort emptyMessage="No videos found" rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} loading={loadingState}
          loadingIcon={
            <CustomLoader />
          }
          style={{ minHeight: 500 }}>
          <Column field="createdAt" header="Date" body={dateBodyTemplate} sortable style={{ width: '7.5%' }}></Column>
          <Column field="title" header="Title" body={titleBodyTemplate} sortable style={{ width: '25%' }}></Column>
          <Column field="status" header="Status" body={statusRejectedBodyTemplate} style={{ width: '12.5%' }}></Column>
          <Column field="campaignId.name" header="Campaign" body={campaignBodyTemplate} sortable style={{ width: '15%' }}></Column>
          <Column field="" header="Author type" body={authorTypeBodyTemplate} style={{ width: '12.5%' }}></Column>
          <Column field="owner[0].name" header="Author" body={authorBodyTemplate} sortable style={{ width: '15%' }}></Column>
          <Column expander={true} body={expanderTemplate} />
        </DataTable>
      }
      {!noResults && <Paginator first={first} rows={limit} totalRecords={videos?.count} onPageChange={onPageChange} style={{ background: '#130A23' }} />}
      <Dialog visible={openApproveDialog} onHide={() => setOpenApproveDialog(false)} className={'reasonDialog'}>
        <div className={'closeRow'}>
          <div className={'closeButton'} onClick={() => { setOpenApproveDialog(false); setApproveReason('') }}><CloseIcon /></div>
        </div>
        <div className={'reasonContent'}>
          <p className={'reasonTitle'}>APPROVE VIDEO</p>
          <p className={'reasonText'}>Before approving the video <strong>({selectedVideo.title})</strong>, give reasoning why.</p>
          <InputTextarea value={approveReason} onChange={(e) => setApproveReason(e.target.value)} rows={5} className={'reasonTextarea'} placeholder={'Approve Reason'} />
          <div className={'reasonButtonGroup'}>
            <Button className={'reasonButtonCancel'} onClick={() => { setOpenApproveDialog(false); setApproveReason('') }}>Cancel</Button>
            <Button className={'reasonButtonSave'} disabled={!approveReason} onClick={() => handleApproveVideo()}>Approve</Button>
          </div>
        </div>
      </Dialog>
      <Dialog visible={openRejectDialog} onHide={() => setOpenRejectDialog(false)} className={'reasonDialog'}>
        <div className={'closeRow'}>
          <div className={'closeButton'} onClick={() => { setOpenRejectDialog(false); setRejectReason('') }}><CloseIcon /></div>
        </div>
        <div className={'reasonContent'}>
          <p className={'reasonTitle'}>REJECT VIDEO</p>
          <p className={'reasonText'}>Before rejecting the video <strong>({selectedVideo.title})</strong>, give reasoning why.</p>
          <InputTextarea value={rejectReason} onChange={(e) => setRejectReason(e.target.value)} rows={5} className={'reasonTextarea'} placeholder={'Reject Reason'} />
          <div className={'reasonButtonGroup'}>
            <Button className={'reasonButtonCancel'} onClick={() => { setOpenRejectDialog(false); setRejectReason('') }}>Cancel</Button>
            <Button className={'reasonButtonSave'} disabled={!rejectReason} onClick={() => handleRejectVideo()}>Reject</Button>
          </div>
        </div>
      </Dialog>
      <PreviewDialog open={openPreviewDialog} closeDialog={handlePreviewDialog} previewDetails={previewDetails} attachmentType="video" />
    </div>
  )
}
