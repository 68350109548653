import React, { memo } from 'react';
import { Chart } from 'primereact/chart';

export const ActualBudgetChart = memo(({ budget }) => {
  const actualBudget = {
    datasets: [
      {
        data: [budget?.leftBudget, (budget?.totalBudget ? budget?.totalBudget : budget?.leftBudget) - budget?.leftBudget],
        backgroundColor: [budget?.totalBudget === budget?.leftBudget ? '#E73D38' : 'lightgray', '#E73D38'],
        borderWidth: 0,
      },
    ],
  };

  const optionsRemaining = {
    cutout: '70%',
    plugins: { legend: { display: false } },
    interaction: { mode: false, intersect: false },
  };

  const findChartPercentage = (totalAmount, amountLeft) => {
    if (totalAmount === amountLeft) return 100;
    else if (amountLeft >= 0) return (100 - Math.ceil((amountLeft / totalAmount) * 100));
    else return 0;
  };

  return (
    <div className='chartWrapper'>
      <Chart type="doughnut" data={actualBudget} options={optionsRemaining} className='chartGraphic' id="actualBudget" />
      <div className='chartPercentage'>{findChartPercentage(budget?.totalBudget, budget?.leftBudget)}%</div>
      <div className='budgetText'>
        <p className='budgetLabel'>Actual Budget</p>
        <p className='budgetValue'>${(budget?.totalBudget === budget?.leftBudget ? budget?.totalBudget : budget?.totalBudget - budget?.leftBudget).toFixed(2)}</p>
      </div>
    </div>
  );
});

export default ActualBudgetChart;
