import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../../public/images/brand-default.svg'


const _withScopeId = n => (_pushScopeId("data-v-c8dc2266"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "p-d-flex p-ai-center profile-block",
  ref: "target"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "p-d-flex p-ai-center profile-block",
  ref: "target"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "image",
  src: _imports_0
}
const _hoisted_6 = {
  key: 2,
  class: "p-d-flex p-ai-center profile-block",
  ref: "target"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "image",
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.user.isCreator() || $setup.user.isAgencyOwner())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "cursor p-d-flex p-ai-center",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.showMenuProfile = !$setup.showMenuProfile), ["prevent"]))
          }, [
            _createElementVNode("img", {
              class: "image",
              alt: "profile photo",
              src: $setup.user.isCreator() ? $setup.user?.profile?.photo : $setup.user?.agencyData?.photo
            }, null, 8, _hoisted_2),
            _createElementVNode("h5", {
              class: _normalizeClass({ 'user-name': $setup.isNewDesignEnabled })
            }, _toDisplayString($setup.user.isCreator() ? $setup.user?.name : $setup.user?.agencyData?.name), 3)
          ]),
          ($setup.showMenuProfile)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([{ 'navigation-wrapper-dark': $setup.isNewDesignEnabled }, "navigation-items p-d-flex p-flex-column"])
              }, [
                _createVNode(_component_router_link, {
                  class: _normalizeClass([{ 'navigation-item-dark': $setup.isNewDesignEnabled }, "navigation-item"]),
                  to: { name: 'creator-account', params: { creatorID: $setup.user?.id }},
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.showMenuProfile = !$setup.showMenuProfile))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" My Account")
                  ]),
                  _: 1
                }, 8, ["class", "to"]),
                _createVNode(_component_router_link, {
                  class: _normalizeClass([{ 'navigation-item-dark': $setup.isNewDesignEnabled }, "navigation-item"]),
                  to: { name: 'creator', params: { creatorID: $setup.user?.id } },
                  onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.showMenuProfile = !$setup.showMenuProfile))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Visit My Profile")
                  ]),
                  _: 1
                }, 8, ["class", "to"]),
                _createVNode(_component_router_link, {
                  class: _normalizeClass([{ 'navigation-item-dark': $setup.isNewDesignEnabled }, "navigation-item"]),
                  to: { name: 'logout' }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Log out")
                  ]),
                  _: 1
                }, 8, ["class"])
              ], 2))
            : _createCommentVNode("", true)
        ], 512))
      : _createCommentVNode("", true),
    ($setup.user.isBrandOwner())
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "cursor p-d-flex p-ai-center",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => ($setup.showMenuProfile = !$setup.showMenuProfile), ["prevent"]))
          }, [
            ($setup.user?.brand?.photo?.length > 0)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "image",
                  src: $setup.user?.brand?.photo
                }, null, 8, _hoisted_4))
              : (_openBlock(), _createElementBlock("img", _hoisted_5)),
            _createElementVNode("h5", {
              class: _normalizeClass({ 'user-name': $setup.isNewDesignEnabled })
            }, _toDisplayString($setup.user?.brand?.name), 3)
          ]),
          ($setup.showMenuProfile)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([{ 'navigation-wrapper-dark': $setup.isNewDesignEnabled }, "navigation-items p-d-flex p-flex-column"])
              }, [
                _createVNode(_component_router_link, {
                  class: _normalizeClass([{ 'navigation-item-dark': $setup.isNewDesignEnabled }, "navigation-item"]),
                  to: { name: 'logout' }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Log out")
                  ]),
                  _: 1
                }, 8, ["class"])
              ], 2))
            : _createCommentVNode("", true)
        ], 512))
      : _createCommentVNode("", true),
    ($setup.user?.type === 'admin')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "cursor p-d-flex p-ai-center",
            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => ($setup.showMenuProfile = !$setup.showMenuProfile), ["prevent"]))
          }, [
            ($setup.user?.brand?.photo?.length > 0)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "image",
                  src: $setup.user?.brand?.photo
                }, null, 8, _hoisted_7))
              : (_openBlock(), _createElementBlock("img", _hoisted_8)),
            _createElementVNode("h5", {
              class: _normalizeClass({ 'user-name': $setup.isNewDesignEnabled })
            }, _toDisplayString($setup.user?.brand?.name), 3)
          ]),
          ($setup.showMenuProfile)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([{ 'navigation-wrapper-dark': $setup.isNewDesignEnabled }, "navigation-items p-d-flex p-flex-column"])
              }, [
                _createVNode(_component_router_link, {
                  class: _normalizeClass([{ 'navigation-item-dark': $setup.isNewDesignEnabled }, "navigation-item"]),
                  to: { name: 'logout' }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Log out")
                  ]),
                  _: 1
                }, 8, ["class"])
              ], 2))
            : _createCommentVNode("", true)
        ], 512))
      : _createCommentVNode("", true)
  ], 64))
}