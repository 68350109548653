<template>
  <div class="app" :class="currentPageName">
    <transition name="fade">
      <div v-if="authenticating" class="loading">
        <progress-spinner class="spinner"/>
      </div>
    </transition>
    <template v-if="!authenticating">
      <router-view/>
    </template>

    <confirm-dialog/>
    <Toast position="top-center"/>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import ProgressSpinner from 'primevue/progressspinner';
import { EventBus } from '@/modules';

export default defineComponent({
  components: { ProgressSpinner, Toast, ConfirmDialog },
  setup() {
    const router = useRouter();
    const route = useRoute();
    onMounted(() => {
      EventBus.on('bad-refresh-error', async () => {
        await router?.push({ name: 'logout' });
      });
    });

    if (route?.query?.invite) {
      localStorage.setItem('inviteCampaign', route.query.invite?.toString());
    }

    const currentPageName = computed(() => router?.currentRoute.value.name);

    return {
      authenticating: false,
      currentPageName,
    };
  },
});
</script>

<style scoped lang="scss">
* {
  font-family: Montserrat, sans-serif;
}

.app {
  .loading {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .spinner {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
