
import { ref, watch } from 'vue';
import { useAuth } from '@/modules';
import { onClickOutside } from '@vueuse/core';
import { UserType } from '../../data/types/user-types';

export default {
  name: 'CreatorMenu',
  props: {
    creator: Object,
  },
  setup() {
    const { user } = useAuth();
    const showMenuProfile = ref(false);
    const target = ref(null);
    onClickOutside(target, () => {
      showMenuProfile.value = false;
    });

    const showModalInflucencerKey = 'show-modal-influencer';
    const showModal = localStorage.getItem(showModalInflucencerKey);
    const isNewDesignEnabled = localStorage.getItem('new-design-toggle') === 'activated';

    return {
      showMenuProfile,
      user,
      target,
      showModalInflucencerKey,
      showModal,
      UserType,
      isNewDesignEnabled
    };
  },
};
