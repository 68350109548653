import React, { useEffect, useState } from "react";

import { Image } from 'primereact/image';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { Paginator } from 'primereact/paginator';
import { DataTable } from 'primereact/datatable';

import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';
import HeaderTooltip from '@/react_app/components/CustomToolTip/HeaderTooltip';

import { CampaignPaymentMethod } from '@/data/types';
import { calculateCommission } from '@/utils/globals/numbers-utils';

import { ExpandArrowDown, ExpandArrowUp } from '../../img/Icons';
import { AndroidIcon, AppleIcon } from '@/react_app/img/Icons';


const creatorTemplate = (data) => {
    return (
        <div className={'creatorTemplate'}>
            <Image src={data.photo} alt={'creator photo'} className={'creatorTemplatePhoto'}></Image>
            <p className={'creatorTemplateName'}>{data.name}</p>
        </div>
    )
};
const creatorEmailTemplate = (data) => (
    <div className="p-d-flex creatorTemplateName">
        <span className="value link" onClick={() => window.location = `mailto:${data?.email}`}>{data?.email || "-"}</span>
    </div>
);
const creatorTypeTemplate = (data) => (
    <div className="p-d-flex creatorTemplateName">
        <span className="value capital">{data?.userType || "-"}</span>
    </div>
);
const totalEarningsTemplate = (data) => (
    <div className={'defaultTemplate'}>
        $ {data.totalEarnings?.toFixed(2) || 0}
    </div>
);
const totalPaidTemplate = (data) => (
    <div className={'defaultTemplate'}>
        $ {data.totalPaid?.toFixed(2) || 0}
    </div>
);
const eligibleForPayoutTemplate = (data) => (
    <div className={'defaultTemplate'}>
        $ {data.amountEarnedThisCycle?.toFixed(2) || 0}
    </div>
);
const gigaPayTemplate = (data) => (
    <div className={'defaultTemplate'}>
        {data.gigapayStatus ? 'Yes' : 'No'}
    </div>
);
const campaignTemplate = (row) => (
    <div className="p-d-flex creatorTemplateName">
        <Avatar className="p-overlay-badge  p-mr-2 photo" image={row?.campaignPhoto} size="xlarge"></Avatar>
        <span className="value">{row?.campaignName}</span>
    </div>
);

const campaignTypeTemplate = (row) => (
    <div className="p-d-flex creatorTemplateName">
        <span className="value">{row?.campaignPaymentMethod}</span>
    </div>
);
const campaignPaymentAmountTemplate = (row) => (
    <>
        {row?.campaignPaymentMethod === CampaignPaymentMethod.CPC &&
            <span className='coverValue'>{row?.cpc.amount} {row?.cpc.currency}</span>
        }
        {row?.campaignPaymentMethod === CampaignPaymentMethod.CPM &&
            <span className='coverValue'>{row?.cpm.amount} {row?.cpm.currency}</span>
        }
        {row?.campaignPaymentMethod === CampaignPaymentMethod.CPI &&
            <>
                <div className='coverValue'>
                    <AndroidIcon />
                    {`${row?.cpi.android.amount} ${row?.cpi.android.currency}`}
                </div>
                <div className='coverValue'>
                    <AppleIcon />
                    {`${row?.cpi.ios.amount} ${row?.cpi.ios.currency}`}
                </div>
            </>
        }
    </>
);
const campaignCommissionRateTemplate = (row) => (
    <div className="defaultTemplate">
        <span className="value">{row?.appliedFee || 0}%</span>
    </div>
);
const campaignEarnedTemplate = (row, isWithCommission) => (
    <div className="p-d-flex p-flex-column defaultTemplate">
        <span className="value">{isWithCommission ? row?.earned?.toFixed(2) : calculateCommission(row?.earned, row?.appliedFee)} USD</span>
        {row?.campaignPaymentMethod === "CPM" && !isWithCommission && <span>Views: {row?.earnedStats?.views?.toLocaleString() || 0} </span>}
        {row?.campaignPaymentMethod != "CPM" && <div className="p-d-flex p-flex-column">
            <span>Android: {row?.earnedStats?.android || 0}</span>
            <span>IOS: {row?.earnedStats?.ios}</span>
            <span>Clicks: {row?.earnedStats?.clicks}</span>
        </div>}
    </div>
);
const campaignPaidTemplate = (row, isWithCommission) => (
    <div className="p-d-flex p-flex-column defaultTemplate">
        <span className="value">{isWithCommission ? row?.paid?.toFixed(2) : calculateCommission(row?.paid, row?.appliedFee)} USD</span>
        {row?.campaignPaymentMethod === "CPM" && !isWithCommission && <span>Views: {row?.paidFor?.views?.toLocaleString() || 0} </span>}
        {row?.campaignPaymentMethod != "CPM" && <div className="p-d-flex p-flex-column">
            <span>Android: {row?.paidStats?.android || 0}</span>
            <span>IOS: {row?.paidStats?.ios || 0}</span>
            <span>Clicks: {row?.paidStats?.clicks || 0}</span>
        </div>}
    </div>
);
const channelTemplate = (row, rowData) => {
    const channel = row?.channelId
        ? rowData?.agencyWorkers?.find(ch => ch.channel?.channelId === row.channelId)
        : null;

    const channelUrl = channel?.channel?.channelId
        ? `https://www.youtube.com/channel/${channel.channel.channelId}`
        : `https://www.youtube.com/channel/${rowData?.youtube?.slice(-1)?.[0]?.id}`;

    const channelTitle = row?.channelId
        ? channel?.channel?.title
        : rowData?.youtube?.slice(-1)?.[0]?.name || '-';

    return (
        <div className='channelTemplate'
            onClick={() => channelUrl && window.open(channelUrl, "_blank")}
            style={{ cursor: channelUrl ? 'pointer' : 'default' }}>
            {channelTitle}
        </div>
    );
};


export default function (props) {

    const { earnings, changePage, limit, loading } = props;
    const tooltipOptions = { position: 'top', style: { display: 'inline-block' } };

    const [first, setFirst] = useState(0);
    const [noResults, setNoResults] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);

    useEffect(() => {
        setNoResults(!(earnings?.creatorEarnings?.length))
    }, [earnings]);

    const expanderTemplate = (rowData) => (
        <div className='expanderTemplate'>
            {expandedRows.includes(rowData) ?
                <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
                    <ExpandArrowUp />
                </div> :
                <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
                    <ExpandArrowDown />
                </div>
            }
        </div>
    )
    const onPageChange = (event) => {
        const { first } = event;
        setExpandedRows([])
        setFirst(first);
        changePage(first);
    };
    const rowExpansionTemplate = (rowData) => (
        <DataTable value={rowData?.links} className="w-100">
            <Column field="campaignName" header="Campaign" body={campaignTemplate} style={{ width: '12%' }} />
            <Column field="channel" header="Channel" body={(data) => channelTemplate(data, rowData)} style={{ width: '8%' }} />
            <Column field="campaignPaymentMethod" header="Campaign Type" style={{ width: '5%' }} body={campaignTypeTemplate} />
            <Column field="campaignPayment" header="Rate" body={campaignPaymentAmountTemplate} style={{ width: '8%' }} />
            <Column field="appliedFee" header="Commission" body={campaignCommissionRateTemplate} style={{ width: '8%' }} />
            <Column field="earned" body={(data) => campaignEarnedTemplate(data, true)} style={{ width: '15%', cursor: 'pointer' }} header={<HeaderTooltip title="Earned (With Commission)" />}
                headerTooltip="Amount pending for this influencer, before deducting commission, based on performance."
                headerTooltipOptions={tooltipOptions} />
            <Column field="earned" body={(data) => campaignEarnedTemplate(data, false)} style={{ width: '15%', cursor: 'pointer' }}
                header={<HeaderTooltip title="Earned (Without Commission)" />} headerTooltip="Amount pending for this influencer, post-commission."
                headerTooltipOptions={tooltipOptions} />
            <Column field="paid" body={(data) => campaignPaidTemplate(data, true)} style={{ width: '15%', cursor: 'pointer' }} headerTooltipOptions={tooltipOptions}
                header={<HeaderTooltip title="Paid (With Commission)" />} headerTooltip="Amount already settled for this influencer, before deducting commission." />
            <Column field="paid" body={(data) => campaignPaidTemplate(data, false)} style={{ width: '15%', cursor: 'pointer' }} header={<HeaderTooltip title="Paid (Without Commission)" />}
                headerTooltip="Amount already settled for this influencer, post-commission." headerTooltipOptions={tooltipOptions} />
        </DataTable>
    );

    return (
        <>
            <DataTable value={earnings?.creatorEarnings || []} emptyMessage="No earnings found" rowExpansionTemplate={rowExpansionTemplate}
                expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                loading={loading} loadingIcon={<CustomLoader />} style={{ minHeight: 500 }} >
                <Column expander={true} body={expanderTemplate} />
                <Column field="name" header="Influencer" sortable body={creatorTemplate} style={{ width: '15%' }} />
                <Column field="userType" header="User Type" sortable body={creatorTypeTemplate} style={{ width: '10%' }} />
                <Column field="email" header="Email" body={creatorEmailTemplate} style={{ width: '10%' }} />
                <Column field="totalEarnings" sortable body={totalEarningsTemplate} header={<HeaderTooltip title="Total Earnings" />}
                    headerTooltip="Combined amount of Earned and Paid for all influencers. (This is on the basis of without commission)" headerTooltipOptions={tooltipOptions} />
                <Column field="totalPaid" sortable body={totalPaidTemplate} header={<HeaderTooltip title="Paid Out" />} headerTooltipOptions={tooltipOptions}
                    headerTooltip="Total amount paid in last month's payout to eligible influencers. (This is on the basis of without commission)" />
                <Column field="amountEarnedThisCycle" sortable body={eligibleForPayoutTemplate} header={<HeaderTooltip title="Eligible for Payout" />}
                    headerTooltip=" Amount scheduled for next month's payout cycle. (This is on the basis of without commission)" headerTooltipOptions={tooltipOptions} />
                <Column field="gigapayStatus" header="On Gigapay" sortable body={gigaPayTemplate} />
            </DataTable>
            {!noResults && <Paginator first={first} rows={limit} totalRecords={earnings?.maxCount || 0} onPageChange={onPageChange} style={{ background: '#130A23' }} />}
        </>
    )
}
