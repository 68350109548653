import React from 'react';

import { HintIcon } from '@/react_app/img/Icons';

const HeaderTooltip = (props) => {

    const { title } = props;
    return (
        <div style={styles.container}>
            <span style={styles.text}>{title}</span>
            <HintIcon />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        color: '#b3b3b3',
        fontSize: '14px',
    },
    text: {
        marginRight: '5px',
    }
};

export default HeaderTooltip;
