import React from 'react'

import { Button } from 'primereact/button';

import { useAuth } from '@/modules';
import { ExclamationIcon } from '@/react_app/NewAdmin/img/Icons';

import './StickyToast.css';

const LinkChannelToast = (props) => {

    const { gotoProfile } = props;
    const user = useAuth();

    return (
        <div className="stickyToast">
            <div className='headerContainer'>
                <ExclamationIcon />
                <span className="title">Link your YouTube channel</span>
            </div>
            <div>
                <p className='subTitle'>Don’t forget to add your YouTube channel to apply for campaigns!</p>
            </div>
            <div className='actionButton'>
                <Button className="update-button" onClick={() => gotoProfile(`creators/${user?.user?.value?.id}/account`)}>Add YouTube</Button>
            </div>
        </div>

    )
}
export default LinkChannelToast