import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { Image } from 'primereact/image';
import { Carousel } from 'primereact/carousel';

import GigaPayToast from "./StickyToast/GigaPayToast";
import LinkChannelToast from "./StickyToast/LinkChannelToast";
import NewInfluencerItem from "@/react_app/NewLatestItems/NewInfluencerItem/NewInfluencerItem";
import NewActiveCampaignItem from "@/react_app/NewLatestItems/NewActiveCampaignItem/NewActiveCampaignItem";

import router from '@/router';
import { useAuth } from '@/modules';
import { Perspective } from "@/data/types";
import { useSocketIo, useSocketMethods } from '@/services/socket';

import campaign1 from "../img/campaign1.avif";
import campaign2 from "../img/campaign2.jpg";
import campaign3 from "../img/campaign3.jpg";
import { motion, AnimatePresence } from 'framer-motion';

import "./NewDashboard.css";

const products = [
  {
    photo: campaign1,
    id: '6650bc41671c35e0440783b3',
  },
  {
    photo: campaign2,
    id: '66515132671c35e0440c8a7a',
  },
  {
    photo: campaign3,
    id: '655ca84526d9c57b725b6047',
  },
];

export default function (props) {

  const { notificationMethods, goToInfluencerProfile, redirectToUrl } = props;
  const { user } = useAuth();
  const {
    id, type, mangopay, youtube, agencyWorkers
  } = user.value;
  const socket = useSocketIo(id);
  const socketMethods = useSocketMethods(socket);

  const [notificationList, setNotificationList] = useState([]);
  const limit = 5;
  const skip = 0;

  const productTemplate = (product) => {
    return (
      <div className={'carouselPhotoWrapper'} onClick={() => props.goToCampaign(product.id)}>
        <Image src={product.photo} alt={'Campaign Photo'} width={'100%'} className={'carouselPhoto'} />
      </div>
    );
  };
  const geNotifications = async () => {
    socketMethods.getLatestNotifications(limit, skip);
    socket.on('latest-notifications', (data) => {
      setNotificationList(data);
    });
  };
  const handleNotification = (notification) => {
    if (notification.status === 'new') socketMethods.changeNotifyStatusRead(notification.id);
    if (notificationMethods.methods.hasOwnProperty(notification.type)) {
      notificationMethods.methods[notification.type](notification, type);
    } else {
      setTimeout(() => {
        geNotifications();
      }, 800);
    }
  }

  useEffect(() => {
    geNotifications();
  }, [])

  const validateYoutubeChannel = () => {
    if (type === 'agencyOwner') {
      return agencyWorkers?.length > 0;
    }
    else if (type === 'creator') {
      return youtube?.length > 0;
    }

    return false;
  };

  return (
    <div className='new-dashboard'>
      <div className={(['admin', 'brandOwner'].includes(type) || (mangopay && validateYoutubeChannel())) ? 'whiteBg' : 'noBg'}>
        <p className='redTitle'>Welcome back, {(props.user?.type === "agencyOwner") ?  props.user?.agencyData?.name : props.user.name}</p>
        {props.viewPerspective === Perspective.BRAND && <p className={'smallText'}>From here you can check out creators, content items, running campaigns or create a new campaign!</p>}
        {(props.viewPerspective === Perspective.CREATOR && mangopay && validateYoutubeChannel()) &&
          <p className={'smallText'}>From here you can check out new campaigns, create content, keep track of your earnings!</p>
        }
        {['creator', 'agencyOwner'].includes(type) && <div className='toastContainer'>
          {!mangopay && <GigaPayToast gotoProfile={redirectToUrl} />}
          {!validateYoutubeChannel() && <LinkChannelToast gotoProfile={redirectToUrl} />}
        </div>}
      </div>
      {type === 'admin' && notificationList?.length > 0 && <div className='notificationPanel'>
        <div className='notificationHeader'>
          <p className='notificationTitle'>Latest Notifications</p>
          <p className='notificationAll' onClick={() => router.push({ name: 'notifications' })}>View all</p>
        </div>
        <AnimatePresence>
          {notificationList?.map((notification) => {
            return (
              <motion.div
                key={notification.id}
                initial={{ scale: 1, opacity: 0.8, y: 20 }}
                animate={{ scale: 1, opacity: 1, y: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ type: 'keyframes', duration: 0.5 }}
                className='notificationMain'
              >
                <div className='notificationContent' onClick={() => handleNotification(notification)}>
                  <span className='min-w-90'>{moment(notification.createdAt).format("Do MMM")} :</span>
                  <p dangerouslySetInnerHTML={{ __html: notification.message }} />
                </div>
              </motion.div>
            )
          })}
        </AnimatePresence>
      </div>}
      <div className={'latestCampaigns'}>
        <p className={'labelCampaigns'}>Latest Campaigns</p>
        <Carousel value={products} numVisible={1} numScroll={1} itemTemplate={productTemplate} autoplayInterval={6000} />
      </div>
      {(props.viewPerspective !== Perspective.CREATOR && props.viewPerspective !== Perspective.AGENCY) &&
        <div className={'latestInfluencers'}>
          <div className={'labelRow'}>
            <p className={'label'}>Trending influencers</p>
            <p className={'seeAll'} onClick={() => props.handleRouting('/app/creators/')}>See All</p>
          </div>
          <div className={'latestInfluencersGrid'}>
            {props.latestInfluencers?.map((item, index) =>
              <div key={item.id} className={'influencerItem'}>
                <NewInfluencerItem influencer={item} goToProfile={goToInfluencerProfile}
                  addToFavorites={props.addToFavorites} favorites={props.favorites} />
              </div>)}
          </div>
        </div>
      }
      <div className={'activeCampaigns'}>
        <div className={'labelRow'}>
          <p className={'label'}>Active Campaigns</p>
          <p className={'seeAll'} onClick={() => props.handleRouting('/app/campaigns')}>See All</p>
        </div>
        <div className={'activeCampaignsGrid'}>
          {props.activeCampaigns?.map((item, index) =>
            <div key={index} className={'campaignWrapper'}>
              <NewActiveCampaignItem campaign={item} goToCampaign={props.goToCampaign} userType={user?.value?.type} />
            </div>)}
        </div>
      </div>
    </div>
  )
}
