import React, { useEffect, useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';

import { useAuth, useCommonApi } from '@/modules';

import Skeleton from 'react-loading-skeleton';

import CampaignFilterMenu from '@/react_app/Other/CampaignFilterMenu/CampaignFilterMenu';
import NewActiveCampaignItem from "@/react_app/NewLatestItems/NewActiveCampaignItem/NewActiveCampaignItem";

import "./NewCampaigns.css";
import 'primeicons/primeicons.css';
import 'react-loading-skeleton/dist/skeleton.css';

export default function (props) {

  const {
    totalCount, limit, isLoading, campaigns, getCampaigns
  } = props;

  const { user: { value: creator } } = useAuth();
  const { manager: { getMasterData } } = useCommonApi();

  const [time, setTime] = useState("");
  const [first, setFirst] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [languageList, setLanguageList] = useState([]);

  const [extendedCategories, setExtendedCategories] = useState({
    platform: true,
    region: false,
    genre: false,
    agelimit: false,
    language: false,
  });

  const [campaignFilters, setCampaignFilters] = useState({
    platform: [],
    region: [],
    genre: [],
    agelimit: [],
    language: [],
  });

  useEffect(() => {
    setNoResults(!(campaigns?.length))
  }, [campaigns]);

  const onPageChange = (event) => {
    setFirst(event.first);
    getCampaigns(
      event.first,
      searchValue,
      'all',
      campaignFilters?.agelimit.length > 0,
      campaignFilters?.genre?.map(item => item.id)?.join(','),
      campaignFilters?.platform?.map(item => item.id)?.join(','),
      campaignFilters?.language?.map(item => item.id)?.join(','),
      campaignFilters?.region?.map(item => item.id)?.join(',')
    )
  };
  const handleSearch = (value) => {
    clearTimeout(time);
    setSearchValue(value);
    setTime(
      setTimeout(() => {
        setFirst(0);
        getCampaigns(
          0,
          value,
          'all',
          campaignFilters?.agelimit.length > 0,
          campaignFilters?.genre?.map(item => item.id)?.join(','),
          campaignFilters?.platform?.map(item => item.id)?.join(','),
          campaignFilters?.language?.map(item => item.id)?.join(','),
          campaignFilters?.region?.map(item => item.id)?.join(',')
        )
      }, 800)
    );
  };

  useEffect(() => {
    getOptionsList();
  }, []);

  useEffect(() => {
    if (campaignFilters) {
      setFirst(0);
      getCampaigns(
        0,
        searchValue,
        'all',
        campaignFilters?.agelimit.length > 0,
        campaignFilters?.genre?.map(item => item.id)?.join(','),
        campaignFilters?.platform?.map(item => item.id)?.join(','),
        campaignFilters?.language?.map(item => item.id)?.join(','),
        campaignFilters?.region?.map(item => item.id)?.join(',')
      )
    }
  }, [campaignFilters]);

  const getOptionsList = () => {
    getMasterData('languages').then((res) => {
      if (res) {
        setLanguageList(res.data.map(language => {
          return {
            value: language.id, name: language.name, id: language.id, code: language.code, isActive: language.isActive
          }
        }));
      } else {
        setLanguageList([]);
      }
    });
  };

  const toggleExtended = (category) => {
    setExtendedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const handleCheckboxChange = (item, category) => {
    setCampaignFilters((prev) => {
      const currentItems = prev[category];
      const updatedItems = currentItems?.includes(item)
        ? currentItems?.filter((i) => i !== item)
        : [...currentItems, item];
      return { ...prev, [category]: updatedItems };
    });
  };


  return (
    <div className={'campaigns'}>
      <div className={'titleRow'}>
        <p className={'title'}>Campaigns {`${!isLoading ? `(${props.totalCount || 0})` : 0}`}</p>
      </div>
      <div className={'content'}>
        <CampaignFilterMenu
          platformOptions={[]}
          languageOptions={languageList}
          extendedCategories={extendedCategories}
          toggleExtended={toggleExtended}
          campaignFilters={campaignFilters}
          handleCheckboxChange={handleCheckboxChange}
          showDefaultFilter={true}
        />
        <div className={'campaignsResult'}>
          <div className={'searchBar'}>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText placeholder="Search for campaigns" onChange={(e) => handleSearch(e.target.value)} value={searchValue} />
            </span>
            {!noResults &&
              <Paginator first={first} rows={limit} totalRecords={totalCount} onPageChange={onPageChange} template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }} />
            }
          </div>
          {isLoading ?
            <div className={'campaignsGrid'}>
              {[0, 1, 2, 3, 4, 5, 6, 7].map((campaign, index) =>
                <div key={index} className={'campaignItem'}>
                  <Skeleton count={1} />
                </div>)}
            </div>
            :
            <div>
              <div className={'campaignsGrid'}>
                {campaigns?.map((campaign, index) =>
                  <div key={index} className={'campaignItem'}>
                    <NewActiveCampaignItem campaign={campaign} goToCampaign={props.goToCampaign} userType={creator.type} />
                  </div>)}
                {noResults &&
                  <p className={'noResult'}>There are no campaigns matching the current search.</p>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
