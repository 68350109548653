
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import Button from "primevue/button";
import { CampaignPaymentMethod, UserType } from "@/data/types";
import Dialog from "primevue/dialog";
import OverlayPanelModal from "@/pages/commons/modals/OverlayPanelModal.vue";
import { useCreators, useAuth, useContentItems, useToastModule } from "@/modules";
import { useCampaignContracts } from "@/modules/campaigns/campaign-contracts-module";
import { useCampaignDeliverables } from "@/modules/campaigns/campaign-deliverables-module";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import TrackingLink from "@/pages/creators/utils/TrackingLink.vue";
import StatusChip from "../commons/status/StatusChip.vue";
import NotificationTip from "./NotificationTip.vue";
import { formatDate, timeAgo } from "../../utils/globals/date-utils";
import { calculateCpiRate } from "../../utils/globals/cpi-rate";
import SubmissionModal from "./SubmissionModal.vue";
import { PUBLIC_VIDEO_MESSAGE } from '@/utils/globals/constants';

export default {
  name: "SubmissionItem",
  components: {
    Column,
    DataTable,
    Button,
    Dialog,
    StatusChip,
    NotificationTip,
    SubmissionModal,
    TrackingLink,
    OverlayPanelModal,
  },
  props: {
    viewPerspective: String,
  },
  setup(props: any) {
    const isEditLink = ref();
    const uploadingLink = ref(false);
    const responseType = ref();
    const route = useRoute();
    const deliverablesManager = useCampaignDeliverables(props.viewPerspective);
    const toast = useToastModule();
    const contractManager = useCampaignContracts(props.viewPerspective);

    const showTerms = ref(false);
    const acceptedTerms = ref(false);
    const noVideoInfoError = ref(false);
    const selectContent = ref();
    const link = ref("");
    const editedLink = ref();
    const dataActiveSubmission = ref();
    const contract = ref();
    const selectedID = ref();
    const openModal = ref(false);
    const showTutorials = ref(false);
    const { id: submissionID, campaignID } = route.params;
    const agencyOwner = ref();
    const showSubmissionModal = ref(false);
    const showBadChannelError = ref(false);

    const updateDataPage = () => {
      contractManager.loadSingle(campaignID as string, submissionID as string).then((campaignWithContract) => {
        contract.value = campaignWithContract?.campaign;
        agencyOwner.value = campaignWithContract?.agencyOwner;
        if (campaignWithContract && campaignWithContract.campaign.contracts) {
          dataActiveSubmission.value = campaignWithContract?.campaign?.contracts[0]?.deliverables[0];
        }
      });
    };
    updateDataPage();

    const copyLink = (url: any) => {
      navigator.clipboard.writeText(url);
    };
    const { user } = useAuth();
    const controller = useCreators();
    const content = useContentItems();
    const latestSubmits = ref();
    content.list.loadMyLatest(user.value?.id as string, 3).then((data) => {
      latestSubmits.value = data?.contentItems.filter((item) => item?.campaignId?.id || item?.campaignId === route.params?.campaignID);
    });
    const sendLink = async (deliverableID: string) => {
      uploadingLink.value = true;
      let IDs = [];
      if (link.value.includes("youtu.be")) {
        IDs = ["&id=" + link.value.split("/").filter(Boolean)[2]];
      } else {
        IDs = [`&id=${link.value.split("=")[1]}`];
      }
      const videosInfo = ref();
      if (!user.value) {
        return false;
      }
      if (contract.value?.paymentMethod === CampaignPaymentMethod.CPM && deliverableID) {
        toast.showError("You have already submitted a video.");
        return false;
      }
      await controller.youtube.getVideosInfo(user.value.id, IDs).then(async ({ videos }) => {
        const item = {
          publishedAt: videos.items[0]?.snippet?.publishedAt,
          title: videos.items[0]?.snippet.title,
          description: videos.items[0]?.snippet.description,
          thumbnails: videos.items[0]?.snippet.thumbnails,
          statistics: videos.items[0]?.statistics,
          channelId: videos.items[0]?.snippet?.channelId,
          url: link.value,
          status: videos.items[0]?.status,
          vid: videos.items[0]?.id,
          photos: [
            videos.items[0]?.snippet.item?.thumbnails?.maxres?.url ||
              videos.items[0]?.snippet.item?.thumbnails?.standard.url ||
              videos.items[0]?.snippet.thumbnails?.high.url ||
              videos.items[0]?.snippet.thumbnails?.medium.url ||
              videos.items[0]?.snippet.thumbnails?.default.url,
          ],
        };
        videosInfo.value = item;
        if (videosInfo.value?.status?.privacyStatus !== "public" && !route.query?.draftAccepted) {
          toast.showError(PUBLIC_VIDEO_MESSAGE);
          return false;
        }
        return true;
      });
      if (videosInfo.value?.status?.privacyStatus !== "public" && contract.value?.paymentMethod === CampaignPaymentMethod.CPM) {
        toast.showError(PUBLIC_VIDEO_MESSAGE);
        return false;
      }

      if (!user.value?.youtube?.length && user.value.type == UserType.Creator) {
        toast.showError("Add your YouTube channel to your profile before uploading a video");
        return false;
      }
      if (process.env.VUE_ENABLE_CHANNEL_CHECKING === "true") {
        if (contract.value?.paymentMethod === CampaignPaymentMethod.CPM) {
          if (user.value?.agencyWorkers?.length && user.value.type == UserType.Agency) {
            const isChannelIndex = user.value.agencyWorkers.findIndex((item) => item?.channel?.channelId === videosInfo.value?.channelId);
            if (isChannelIndex < 0) {
              toast.showError("Error adding video");
              showBadChannelError.value = true;
              return false;
            }
          }
        } else {
          if (user.value.type === UserType.Creator && user.value.youtube?.length) {
            const isChannelIndex = user.value.youtube.findIndex((item) => item?.id && item?.id === videosInfo.value?.channelId);
            if (isChannelIndex < 0) {
              toast.showError("Error adding video");
              showBadChannelError.value = true;
              return false;
            }
          }
        }
      }
      showBadChannelError.value = false;

      if (!videosInfo.value.vid) {
        noVideoInfoError.value = true;
        uploadingLink.value = false;
        return false;
      }
      const contentItem = await content.manager.createContent(user.value.id, videosInfo.value, route.params.campaignID as string, route.query.channel as string);

      if (!deliverableID) {
        await deliverablesManager.submit(contract.value?.id, contract.value?.contracts[0]?.id, {
          link: link.value,
          contentItemId: contentItem.id,
        });
      } else {
        await deliverablesManager.submit(contract.value?.id, contract.value?.contracts[0]?.id, {
          link: link.value ? link.value : "",
          deliverableID,
          contentItemId: contentItem.id,
        });
      }
      await contractManager.loadSingle(campaignID as string, submissionID as string).then((data) => {
        contract.value = data;
      });
      link.value = "";
      uploadingLink.value = false;
      updateDataPage();
      return true;
    };

    const optionsForChips = ref();

    watch(responseType, () => {
      if (responseType.value === "approve") {
        optionsForChips.value = ["Great work!", "Nice video! You completed the brief!"];
      } else if (responseType.value === "reject") {
        optionsForChips.value = ["The quality of the video is poor.", "The audio could be better.", "Please stick to the brief."];
      }
    });
    const saveEditLink = async (linkId: string, contractId: string, deliverableId: string, channelId?: string) => {
      isEditLink.value = null;
      const IDs = [`&id=${editedLink.value.split("=")[1]}`];
      const videosInfo = ref();
      if (!user.value) {
        return;
      }
      await controller.youtube.getVideosInfo(user.value.id, IDs).then(async (data: any) => {
        const item = {
          publishedAt: data.videos.items[0]?.snippet?.publishedAt,
          title: data.videos.items[0]?.snippet.title,
          description: data.videos.items[0]?.snippet.description,
          thumbnails: data.videos.items[0]?.snippet.thumbnails,
          statistics: data.videos.items[0]?.statistics,
          url: link.value,
          vid: data.videos.items[0]?.id,
          photos: [
            data.videos.items[0]?.snippet.item?.thumbnails?.maxres?.url ||
              data.videos.items[0]?.snippet.item?.thumbnails?.standard.url ||
              data.videos.items[0]?.snippet.thumbnails?.high.url ||
              data.videos.items[0]?.snippet.thumbnails?.medium.url ||
              data.videos.items[0]?.snippet.thumbnails?.default.url,
          ],
        };
        videosInfo.value = item;
      });
      const contentItem = await content.manager.createContent(user.value.id, videosInfo.value, route.params.campaignID as string, channelId);
      await deliverablesManager.editLink(campaignID as string, contractId, deliverableId, linkId, editedLink.value, contentItem.id);
      editedLink.value = null;
      updateDataPage();
    };

    const postThroughModal = (theLink: any) => {
      link.value = theLink.value;
      sendLink(dataActiveSubmission?.value?.id).then((success) => {
        if (success) {
          window.setTimeout(() => {
            showSubmissionModal.value = false;
            window.location.reload();
            toast.showSuccess("Video Uploaded Successfully");
          }, 1000);
        }
      });
    };

    return {
      showTutorials,
      postThroughModal,
      showSubmissionModal,
      selectedID,
      selectContent,
      content,
      latestSubmits,
      showBadChannelError,
      latestContent: content.list.loadMyLatestResult,
      uploadingLink,
      showTerms,
      acceptedTerms,
      openModal,
      optionsForChips,
      responseType,
      noVideoInfoError,
      contract,
      agencyOwner,
      formatDate,
      timeAgo,
      copyLink,
      saveEditLink,
      calculateCpiRate,
      link,
      editedLink,
      isEditLink,
      sendLink,
      dataActiveSubmission,
      user,
      CampaignPaymentMethod,
    };
  },
};
