import React from 'react';

export const ViewCountChart = ({ budget, currentTotalViewCount }) => {
  return (
    <div className={'chartWrapper'}>
      <div className={'budgetText'}>
        <p className={'budgetLabel'}>View Count</p>
        <p className={'budgetValue'}>{Math.round(currentTotalViewCount).toLocaleString()}</p>
      </div>
    </div>
  );
};

export default ViewCountChart;
