export const DEFAULT_CAMPAIGN_FEE = 30;
export const DEFAULT_UGC_CAMPAIGN_FEE = 50;


export const calculateCpiRate = (amount: number, appliedFee: number = DEFAULT_CAMPAIGN_FEE) => {
  const value = (amount - amount * (appliedFee / 100)).toFixed(2);
  return ((value === "NaN" || !value) ? 0 : value)
};

export const calculateRoundedViews = async (CAP:number, creatorCap:number) => {
  
  const originalViews = (CAP / creatorCap) * 1000;
  const lastTwoDigits = originalViews % 100;
  let initialRoundedViews = 0;
  if (lastTwoDigits >= 50) {
    initialRoundedViews = Math.ceil(originalViews / 100) * 100; // Round up to the nearest 100
  } else {
    initialRoundedViews = Math.floor(originalViews / 100) * 100; // Round down to the nearest 100
  }
  return initialRoundedViews ;
}