import { useToastModule } from "@/modules";

export function scrollToComponent(event: any) {
  const targetId = event.target.id;
  // Ensure the targetId is valid
  if (!targetId) {
    return;
  }
  const targetElement = document.querySelector(`#${targetId}`);
  if (targetElement) {
    const startPosition = window.pageYOffset;
    const targetPosition = targetElement.getBoundingClientRect().top + startPosition;
    const distance = targetPosition - startPosition;
    const duration = 900;
    let start: any = null;

    const animateScroll = (currentTime: any) => {
      if (!start) {
        start = currentTime;
      }
      const timeElapsed = currentTime - start;
      const ease = easeInOutCubic(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, ease);
      if (timeElapsed < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  }
}

export function easeInOutCubic(t: number, b: number, c: number, d: number) {
  t /= d / 2;
  if (t < 1) {
    return c / 2 * t * t * t + b;
  }
  t -= 2;
  return c / 2 * (t * t * t + 2) + b;
}

export function discord() {
  const url = 'https://discord.com/invite/8CwnjbhTAC';
  const options: Record<string, any> = {
    target: '_blank',
    noopener: true,
    noreferrer: true,
  };
  window.open(url, options.target, Object.keys(options).map(key => `${key}=${options[key]}`).join(','));
}

export async function copyToClipboard(url: string) {
  const toast = useToastModule();
  await navigator.clipboard.writeText(url);
  await toast.showSuccess('You have successfully copied the link');
}

export const scrollToTop = (startLink: any) => {
  startLink.scrollIntoView({
    behavior: 'smooth'
  });
};

export const getTrackingLink = (user:any, campaign:any) => {
  if (user?.isAgencyOwner()) {
    const linkObj = campaign.trackingLinks.find((currentLink:any) => {
      return (
        currentLink.creatorId === user?.id ||
        user?.agencyOwners?.some(
          (agencyWorker:any) =>
            agencyWorker?.channel?.channelId === currentLink?.channelId
        )
      );
    });
    return linkObj ? linkObj.link : null;
  } else if (user?.isCreator()) {
    const linkObj = campaign.trackingLinks.find(
      (currentLink:any) => currentLink.creatorId === user?.id
    );
    return linkObj ? linkObj.link : null;
  }
  return null;
};

