import React, { useState } from 'react';

import { Image } from "primereact/image";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from 'primereact/progressspinner';

import { useCreators } from '@/modules';
import { CampaignPaymentMethod } from "@/data/types";
import { CloseIcon, WarningIcon } from "@/react_app/img/Icons";

import "./NewInvitations.css";
import CustomLoader from '../Other/CustomLoader/CustomLoader';

const brandBodyTemplate = (rowData) => {
  return (
    <div className='brandTemplate'>
      <Image src={rowData.brand.photo} alt={'brand photo'} className='brandTemplatePhoto' />
      {rowData.brand.name}
    </div>
  );
};
const budgetBodyTemplate = (rowData) => {
  return (
    <div className='budgetTemplate'>
      $ {rowData.budget.amount}
    </div>
  );
};
const ratesBodyTemplate = (rowData) => {
  return (
    <div className='ratesTemplate'>
      {rowData.paymentMethod === CampaignPaymentMethod.CPM &&
        <div>
          CPM rates: $ {rowData.cpm.amount}<br />
          Payout cap: $ {rowData.cpm.payoutCap}
        </div>
      }
      {rowData.paymentMethod === CampaignPaymentMethod.CPI &&
        <div>
          Android: $ {rowData.cpi.android.amount}<br />
          iOS: $ {rowData.cpi.ios.amount}
        </div>
      }
      {rowData.paymentMethod === CampaignPaymentMethod.CPC &&
        <div>
          Click: $ {rowData.cpc.amount}<br />
        </div>
      }
    </div>
  );
};
const statusBodyTemplate = (rowData) => {
  return (
    <div className='statusTemplate'>
      {rowData.invitations[0].status === 'pending' &&
        <div className='statusPending'>Pending</div>
      }
      {rowData.invitations[0].status === 'rejected' &&
        <div className='statusRejected'>Rejected</div>
      }
      {rowData.invitations[0].status === 'accepted' &&
        <div className='statusAccepted'>Accepted</div>
      }
    </div>
  );
};

export default function (props) {

  const { loading, campaigns, goToCampaign, updateStatusInvitation } = props;
  const creators = useCreators();

  const [capValue, setCapValue] = useState('');
  const [isLoadingStats, setIsLoadingStats] = useState(false);
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(undefined);

  const getCreatorYoutubeStats = async (channelID, data) => {
    setIsLoadingStats(true);
    await creators.manager.getCreatorCap(channelID, data).then((res) => {
      if (res) setCapValue(res.data?.roundedCAP);
      else setCapValue('');
      setIsLoadingStats(false);
    }).catch(error => {
      if (error) {
        console.log('error', error.message || "Something went wrong.");
        setIsLoadingStats(false);
      }
    });
  };
  const handleAcceptDialog = (flag, campaign) => {
    setIsLoadingStats(true);
    if (flag && campaign?.paymentMethod === 'CPM') {
      const creatorDetails = campaign.invitations[0];
      const data = {
        appliedFee: campaign.appliedFee || 0,
        maxPayoutCap: campaign.cpm?.payoutCap || 0,
        clientCap: campaign.cpm?.amount || 0
      }
        const channelId = creatorDetails.creator?.youtube?.at(-1)?.id;
      if (channelId) getCreatorYoutubeStats(channelId, data);
    } else setCapValue('');

    setSelectedCampaign(campaign);
    setOpenAcceptDialog(flag);
  };
  const campaignBodyTemplate = (rowData) => {
    return (
      <div className='campaignTemplate' onClick={() => goToCampaign(rowData.id)}>
        <Image src={rowData.photo} alt='campaign cover' className='campaignTemplatePhoto' />
        {rowData.name}
      </div>
    );
  };
  const buttonsBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.invitations[0].status === 'pending' &&
          <div className='buttonsTemplate'>
            <Button className='acceptButton' onClick={() => handleAcceptDialog(true, rowData)}>Accept Invite</Button>
            <Button className='declineButton' onClick={() => { setOpenDeclineDialog(true); setSelectedCampaign(rowData) }}>Decline Invite</Button>
          </div>
        }
        {rowData.invitations[0].status === 'rejected' &&
          <div className='buttonsTemplate'>
            <Button className='acceptButton noBackground' onClick={() => handleAcceptDialog(true, rowData)}>Accept Invite</Button>
          </div>
        }
        {rowData.invitations[0].status === 'accepted' &&
          <div className='buttonsTemplate'>
            <Button className='declineButton' onClick={() => goToCampaign(rowData.id)}>Go To Campaign</Button>
          </div>
        }
      </div>
    );
  };
  const deleteInvitation = async (campaignID, invitationID) => {
    await updateStatusInvitation(campaignID, invitationID, 'rejected');
    setOpenDeclineDialog(false);
  };
  const acceptInvitation = async (campaignID, invitationID) => {
    const res = await updateStatusInvitation(campaignID, invitationID, 'accepted', (selectedCampaign.paymentMethod === 'CPM') ? capValue : '');
    handleAcceptDialog(false, '');
  };



  return (
    <div className='invitations'>
      <p className='title'>Campaign Invitations</p>
      <div className='table'>
        <DataTable value={campaigns} removableSort emptyMessage="No results found."
          loading={loading} loadingIcon={<CustomLoader />} style={{ minHeight: 500 }}>
          <Column field="name" header="Campaign" body={campaignBodyTemplate} style={{ width: '33%' }}></Column>
          <Column field="brand.name" header="Brand" body={brandBodyTemplate} style={{ width: '11%' }}></Column>
          <Column field="budget" header="Budget" body={budgetBodyTemplate} style={{ width: '11%' }}></Column>
          <Column field="" header="Cap" body={ratesBodyTemplate} style={{ width: '11%' }}></Column>
          <Column field="status" header="Status" body={statusBodyTemplate} style={{ width: '11%' }}></Column>
          <Column field="" header="" body={buttonsBodyTemplate} style={{ width: '24%' }}></Column>
        </DataTable>
      </div>
      <Dialog visible={openAcceptDialog} onHide={() => handleAcceptDialog(false, '')} className='dialogInvitation'>
        <div className='dialogTop'>
          <div onClick={() => handleAcceptDialog(false, '')}><CloseIcon /></div>
        </div>
        <p className='dialogTitle'>Accept invitation</p>
        {selectedCampaign?.paymentMethod === "CPM" &&
          <p className='dialogText'>Max.Cap:
            {isLoadingStats ? <ProgressSpinner style={{ width: '25px', height: '25px', margin: '10px' }} fill="var(--surface-ground)" animationDuration=".5s" />
              : <span style={{ color: 'red', margin: '0px 5px' }}> {`${capValue || 0} USD `}</span>} for this campaign.</p>}
        <p className='dialogSubText'>Are you sure you want to accept this invitation?</p>
        <div className='buttonGroup'>
          <Button className='noButton' onClick={() => handleAcceptDialog(false, '')}>No</Button>
          <Button className='yesButton backgroundLime' onClick={() => acceptInvitation(selectedCampaign.id, selectedCampaign.invitations[0].id)}
            disabled={(selectedCampaign?.paymentMethod === "CPM") ? (isLoadingStats || !capValue) : false} >Yes</Button>
        </div>
      </Dialog>
      <Dialog visible={openDeclineDialog} onHide={() => setOpenDeclineDialog(false)} className='dialogInvitation'>
        <div className='dialogTop'>
          <div onClick={() => setOpenDeclineDialog(false)}><CloseIcon /></div>
        </div>
        <p className='dialogTitle'>Decline invitation</p>
        <WarningIcon />
        <p className='dialogText'>Are you sure you want to drop this invitation?</p>
        <div className='buttonGroup'>
          <Button className='noButton' onClick={() => setOpenDeclineDialog(false)}>No</Button>
          <Button className='yesButton' onClick={() => deleteInvitation(selectedCampaign.id, selectedCampaign.invitations[0].id)}>Yes</Button>
        </div>
      </Dialog>
    </div>
  )
}
