export const minPasswordLength = 8;

export const minUsernameLength = 4;


export const MESSAGE_WRAPPER = {
  'firstName': "First name is required.",
  'lastName': "Last name is required.",
  'brandName': "Brand name is required.",
  'creatorName': "Influencer name is required",
  'agencyName': "Agency name is required",
  'username': `The name must be at least ${minUsernameLength} characters long.`,
  'invalidUsername': 'This field must not include the @ symbol.',
  'email': "Email is required.",
  'invalidEmail': "Invalid Email.",
  'password': `Password must be at least ${minPasswordLength} characters long.`,
  'verificationCode': "Failed to send verification code.",
  'contentCategory': "Content category is required!",
  'contentLanguage': "Content language is required!",
  'dateOfBirth': "Date of birth is required!",
  'countries': "Country is required!",
  'languages': "Language is required!",
  'platforms': "Platforms links can't be empty",
  'categories': "Content category is required!",
}

export const FILES = { CAMPAIGN_COVER_IMAGE: 1.1 };
export const IMAGE_FORMAT = '.png,.jpg,.jpeg,.gif';
export const FILE_DIMENSION = { height: 1300, width: 2400 };

export const TYPE_OPTIONS = [
  {
    label: 'Influencer',
    value: 'influencer',
    title: 'Login as an influencer gives you access to campaigns published by gaming companies, developers and publishers.',
    subTitle: "Earn money by applying to campaigns and delivering meaningful content."
  },
  {
    label: 'Brand',
    value: 'brand',
    title: 'Login as a brand gives you access to campaigns creation and collaborations with influencers to promote your games.',
    subTitle: 'Push your game to the next level by running scalable campaigns, reaching new audiences and increasing your ROI.'
  },
];
export const STATUS_TAB = [
  { value: 'Active', type: 'active' },
  { value: 'Drafts', type: 'draft' },
  { value: 'Completed', type: 'completed' },
  { value: 'Pending', type: 'pending' },
];

export const PAGINATION_LIMIT = 10;

export const POP_CLOSED_MESSAGE = 'Pop-up closed. Please try again to select at least one account and complete all the steps'
export const PUBLIC_VIDEO_MESSAGE = 'Final video must be in Public state.'