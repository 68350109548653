/* eslint-disable vue/max-len */
import React, { useEffect, useRef, useState } from "react";
import "./NewCreatorAccount.css";
import "./NewCreatorAccountAdditional.css";
import {
  CloseIcon, LoopIcon, RedTriangle, RedX, StarDocumentIcon
} from '../img/Icons';
import InstagramIcon from "../img/instagram.png"
import TwitchIcon from "../img/twitch.png"
import TikTokIcon from "../img/tiktok.png"
import FacebookIcon from "../img/facebook.png"
import KickIcon from "../img/kick.png"
import XIcon from "../img/x.png"
import DiscordIcon from "../img/discord.png"
import NewImageCropper from "@/react_app/Other/NewImageCropper/NewImageCropper";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Image } from "primereact/image";
import { UserType } from "@/data/types";
import { MESSAGE_WRAPPER } from '@/utils/globals/constants';
import noImage from "../img/no-image-1-gray.png";
import { WhitelistDateIcon, WhitelistDropdownIcon } from '../NewAdmin/img/Icons';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import {
  useAgencys, useAuth, useCommonApi, useCreators, useToastModule
} from '@/modules';
import CustomLoader from '../Other/CustomLoader/CustomLoader';
// import { Checkbox } from 'primereact/checkbox';
import ImageUploader from '../Other/ImageUploader/ImageUploader';

const TabStep = {
  influencerInformation: 1,
  channelInformation: 2,
}

const tabItems = [
  { index: TabStep.influencerInformation, name: 'Influencer Information' },
  { index: TabStep.channelInformation, name: 'Channel Information' },
]

const AddNew = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="59" height="59" viewBox="0 0 59 59" fill="none">
      <path d="M29.5 0C21.7053 0.0940405 14.2564 3.23226 8.74433 8.74433C3.23226 14.2564 0.0940405 21.7053 0 29.5C0.0940405
       37.2947 3.23226 44.7436 8.74433 50.2557C14.2564 55.7677 21.7053 58.906 29.5 59C37.2947 58.906 44.7436 55.7677 50.2557
       50.2557C55.7677 44.7436 58.906 37.2947 59 29.5C58.906 21.7053 55.7677 14.2564 50.2557 8.74433C44.7436 3.23226 37.2947
       0.0940405 29.5 0ZM46.3571 31.6071H31.6071V46.3571H27.3929V31.6071H12.6429V27.3929H27.3929V12.6429H31.6071V27.3929H46.3571V31.6071Z"
        fill="#9F9EA0" />
    </svg>
  )
}

const selectedOptionTemplate = (option, props) => {
  if (option) {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  }

  return <span>{props.placeholder}</span>;
};

const dropdownOptionTemplate = (option) => {
  return (
    <div className="flex align-items-center">
      <div>{option.name}</div>
    </div>
  );
};

function LinkFieldIcon(props) {
  return (
    <>
      {props.index === 0 && <Image src={InstagramIcon} alt={'instagram-icon'} />}
      {props.index === 1 && <Image src={TwitchIcon} alt={'twitch-icon'} />}
      {props.index === 2 && <Image src={KickIcon} alt={'kick-icon'} />}
      {props.index === 3 && <Image src={XIcon} alt={'x-icon'} />}
      {props.index === 4 && <Image src={FacebookIcon} alt={'facebook-icon'} />}
      {props.index === 5 && <Image src={TikTokIcon} alt={'tiktok-icon'} />}
      {props.index === 6 && <Image src={DiscordIcon} alt={'discord-icon'} />}
    </>
  )
}

export default function (props) {
  const fileUploadRef = useRef();
  const toast = useToastModule();
  const { user: { value: creatorData }, loadUser } = useAuth();
  const [creator, setCreator] = useState(creatorData);
  const controller = useCreators(props?.config?.value?.viewPerspective);
  const agencyModule = useAgencys();
  const { manager: { getMasterData } } = useCommonApi();
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [openCropper, setOpenCropper] = useState(false);
  const [customUrl, setCustomUrl] = useState('');
  const [availablePhotos, setAvailablePhotos] = useState([]);
  const [openAgencyDialog, setOpenAgencyDialog] = useState(false);
  const [languageList, setLanguageList] = useState([]);
  const [platformOptions, setPlatformOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [contentCategoryOptions, setContentCategoryOptions] = useState([]);
  const [userDetails, setUserDetails] = useState({
    creatorName: "",
    creatorPhoto: "",
    firstName: "",
    lastName: "",
    email: "",
    creatorDescription: "",
    address: {
      AddressLine1: "",
      City: "",
      Country: "",
      PostalCode: 0
    },
    dateOfBirth: "",
    countries: "",
    categories: [],
    languages: creator.type === UserType.Agency ? ["66b064f861c5ce17ffdc11a1"] : "66b064f861c5ce17ffdc11a1",
    // isUGCCreator: false,
    platforms: platformOptions
  });
  const [errorMessage, setErrorMessage] = useState({
    creatorName: "",
    creatorPhoto: "",
    creatorDescription: "",
    address: {
      AddressLine1: "",
      City: "",
      Country: "",
      PostalCode: 0
    },
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    countries: "",
    categories: "",
    languages: creator.type === UserType.Agency ? [] : "",
    // isUGCCreator: false,
    platforms: ""
  });
  const [isEditProfile, setIsEditProfile] = useState(false);

  useEffect(() => {
    if (creator) {
      const updatedPlatforms = platformOptions.map((platform) => {
        const matchingPlatform = (creator?.platforms || []).find(
          (response) => response.platformId === platform.platformId
        );

        if (matchingPlatform) {
          return { ...platform, value: matchingPlatform.value };
        }

        return platform;
      });

      const selectedCountry = countryOptions.find(option => option.value === creator?.countries);

      const updatedDetails = {
        creatorName: creator.name || "",
        creatorDescription: "",
        address: {
          AddressLine1: "",
          City: "",
          Country: "",
          PostalCode: 0
        },
        creatorPhoto: "",
        firstName: "",
        lastName: "",
        email: creator.email || "",
        dateOfBirth: creator?.dateOfBirth || "",
        countries: creator.type === UserType.Agency ? creator?.countries : creator?.countries,
        categories: creator.type === UserType.Agency ? creator?.categories : creator?.categories,
        languages: creator.type === UserType.Agency ? creator?.languages : creator?.languages ? creator?.languages[0] : '',
        // isUGCCreator: creator?.isUGCCreator,
        platforms: updatedPlatforms
      }

      if (creator.type === UserType.Creator) {
        updatedDetails.creatorDescription = creator.profile?.description || "";
        updatedDetails.address.AddressLine1 = creator?.influencerAddress?.AddressLine1 || "";
        updatedDetails.address.City = creator?.influencerAddress?.City || "";
        updatedDetails.address.PostalCode = creator?.influencerAddress?.PostalCode || "";
        updatedDetails.address.Country = selectedCountry?.code || "";
        updatedDetails.creatorPhoto = creator.profile?.photo || "";
        updatedDetails.creatorName = creator.name || "";

      } else if (creator.type === UserType.Agency) {
        updatedDetails.creatorDescription = creator.agencyData?.description || "";
        updatedDetails.address.AddressLine1 = creator.agencyData?.address?.AddressLine1 || "";
        updatedDetails.address.City = creator.agencyData?.address?.City || "";
        updatedDetails.address.PostalCode = creator.agencyData?.address?.PostalCode || "";
        updatedDetails.address.Country = selectedCountry?.code || "";
        updatedDetails.creatorPhoto = creator.agencyData?.photo || "";
        updatedDetails.creatorName = creator.agencyData?.name || "";
      }
      updatedDetails.firstName = creator?.firstName || ""
      updatedDetails.lastName = creator?.lastName || ""

      setUserDetails(updatedDetails);
      setAvailablePhotos([]);
    }
    return () => {
      setErrorMessage({});
    }
  }, [creator, platformOptions]);


  useEffect(() => {
    if (platformOptions.length <= 0) {
      getOptionsList();
    }
  }, [])

  const getOptionsList = () => {
    setIsLoading(true);
    getMasterData('').then((res) => {
      if (res) {
        setIsLoading(false);
        setLanguageList(res.data.languages.map(language => {
          return {
            value: language.id, name: language.name, id: language.id, code: language.code, isActive: language.isActive
          }
        }));
        setPlatformOptions(res.data.platforms.slice(1).map(platform => {
          return {
            platform: platform.name, value: [""], name: platform.name, platformId: platform.id, isActive: platform.isActive
          }
        }));
        setContentCategoryOptions(res.data.categories
          .map(category => {
            return {
              value: category.id, name: category.name, id: category.id, isActive: category.isActive
            }
          }));
        setCountryOptions(res.data.countries.map(country => {
          return {
            value: country.id, name: country.name, id: country.id, code: country.code
          }
        }));
      } else {
        setLanguageList([]);
        setPlatformOptions([]);
        setContentCategoryOptions([]);
      }
    }).catch(error => {
      setIsLoading(false);
    });
  }

  const updateCreator = (payload) => {
    setIsLoading(true);
    controller.manager.putInfluencerInfo(creator.id, payload)
      .then((success) => {
        if (success) {
          toast.showSuccess(`Profile updated successfully!`);
          loadUser().then(response => {
            if (response) {
              setCreator(response);
              setIsLoading(false);
              setCurrentTab(1);
              localStorage.removeItem('socialIn');
              localStorage.setItem('isEditProfile', 'false');
              setIsEditProfile(false);
              props.router.push({ name: 'creator', params: { creatorID: creator.id } });
            }
          }).catch(error => {
            setIsLoading(false);
            toast.showError(error.message || "Something went wrong!");
          })
        }
      }).catch(error => {
        toast.showError(error.message || "Something went wrong!");
        setIsLoading(false);
      });
  };

  const switchAccount = async () => {
    setIsLoading(true);
    setOpenAgencyDialog(false);
    await agencyModule.manager.switchAccount().then(response => {
      if (response) {
        setIsLoading(false);
        loadUser().then(response => {
          if (response) {
            setCreator(response);
            setIsLoading(false);
          }
        }).catch(error => {
          setIsLoading(false);
          toast.showError(error.message || "Something went wrong!");
        })
      }
    }).catch(error => {
      toast.showError("Something went wrong!");
      setIsLoading(false);
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      addCustomPhoto();
    }
  };

  const addCustomPhoto = () => {
    if (customUrl && availablePhotos.length < 8) {
      setAvailablePhotos([...availablePhotos, customUrl])
      setCustomUrl('');
    }
  };

  const handleFileUpload = ({ files }) => {
    const [uploadPhotoInput] = files;

    if (uploadPhotoInput) {
      const file = uploadPhotoInput;
      const reader = new FileReader();
      reader.onload = () => {
        setAvailablePhotos([...availablePhotos, reader.result])
      };
      reader.readAsDataURL(file);
    }

    fileUploadRef.current.clear();
  };

  const getPhoto = () => {
    if (creator?.type === UserType.Creator) {
      return userDetails.creatorPhoto === creator?.profile?.photo ? undefined : userDetails.creatorPhoto;
    }
    else if (creator?.type === UserType.Agency) {
      return userDetails.creatorPhoto === creator?.agencyData?.photo ? undefined : userDetails.creatorPhoto;
    }
  }

  const handleChange = (key, value) => {
    setUserDetails((prev) => ({ ...prev, [key]: value }));
    if (key !== "creatorPhoto") {
      setErrorMessage((prev) => ({ ...prev, [key]: value ? "" : MESSAGE_WRAPPER[key] }));
    }
  };

  const validateForm = () => {
    const newErrors = { ...errorMessage };

    if (!userDetails?.creatorName) {
      newErrors.creatorName = MESSAGE_WRAPPER[creator.type == 'creator' ? 'creatorName' : 'agencyName'];
    } else {
      newErrors.creatorName = "";
    }

    if (!userDetails.firstName) {
      newErrors.firstName = MESSAGE_WRAPPER['firstName'];
    } else {
      newErrors.firstName = "";
    }

    if (!userDetails.lastName) {
      newErrors.lastName = MESSAGE_WRAPPER['lastName'];
    } else {
      newErrors.lastName = "";
    }

    if (!userDetails.dateOfBirth) {
      newErrors.dateOfBirth = MESSAGE_WRAPPER['dateOfBirth'];
    } else {
      newErrors.dateOfBirth = "";
    }

    if (!userDetails.countries) {
      newErrors.countries = MESSAGE_WRAPPER['countries'];
    } else {
      newErrors.countries = "";
    }

    if (userDetails?.categories && userDetails.categories.length < 1) {
      newErrors.categories = MESSAGE_WRAPPER['categories'];
    } else {
      newErrors.categories = "";
    }

    if (!userDetails?.languages && creator.type === UserType.Creator) {
      newErrors.languages = MESSAGE_WRAPPER['languages'];
    } else if (creator.type === UserType.Agency) {
      if (userDetails?.languages && userDetails?.languages?.length < 1) {
        newErrors.languages = MESSAGE_WRAPPER['languages'];
      }
    } else {
      newErrors.languages = "";
    }

    setErrorMessage(newErrors);

    return userDetails.creatorName && userDetails.firstName && userDetails.lastName
  }

  const handleSubmit = () => {
    const isValid = validateForm();
    if (isValid) {
      updateCreator({
        photo: getPhoto(), name: userDetails.creatorName,
        description: userDetails.creatorDescription,
        address: { ...userDetails.address, PostalCode: userDetails.address.PostalCode },
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        creatorName: userDetails.creatorName,
        dateOfBirth: moment(userDetails.dateOfBirth).format('YYYY-MM-DD HH:mm:ss'),
        countries: userDetails.countries,
        categories: userDetails.categories.join(','),
        languages: creator.type === UserType.Agency ? userDetails.languages.join(',') : userDetails.languages,
        platforms: userDetails.platforms
          .map(item => {
            if (item.value !== "") {
              return {
                platformId: item.platformId,
                value: item.value
              };
            }
          })
          .filter(item => item !== undefined)
      })
    }
  }

  const handleSwitch = () => {
    if (creator?.type === UserType.Creator) {
      if (creator?.agencyData?.name) {
        switchAccount();
      } else {
        setOpenAgencyDialog(true);
      }
    }
    else {
      switchAccount();
    }
  }

  const handleGoBack = () => {
    props?.router?.push({ name: 'creator-account', params: { creatorID: creator.id } });
  }

  useEffect(() => {
    if (!!userDetails) {
      validateForm()
    }
  }, [userDetails, creator?.type])

  useEffect(() => {
    const isEditProfileData = localStorage.getItem('isEditProfile');
    if (isEditProfileData) {
      const showBack = JSON.parse(isEditProfileData);
      setIsEditProfile(showBack);
    } else {
      setIsEditProfile(false);
    }
  }, []);

  const handleUrlChange = (e, index, linkIndex) => {
    const newUrls = [...userDetails.platforms];
    newUrls[index].value = [...newUrls[index].value].map((url, i) => (i === linkIndex ? e.target.value : url));
    setUserDetails(prevState => ({
      ...prevState,
      platforms: newUrls
    }));
  };

  const handleRemoveUrl = (index, linkIndex) => {
    const newUrls = [...userDetails.platforms];
    newUrls[index].value = [...newUrls[index].value].filter((url, i) => i !== linkIndex);
    setUserDetails((prevState) => ({ ...prevState, platforms: newUrls }));
  };

  const handleAddUrl = (index) => {
    const newUrls = [...userDetails.platforms];
    newUrls[index].value = [...newUrls[index].value, ''];
    setUserDetails((prevState) => ({ ...prevState, platforms: newUrls }));
  };

  const isFirstTabValid = userDetails.creatorName &&
    userDetails.firstName && userDetails.lastName && userDetails.email &&
    userDetails.dateOfBirth && userDetails.countries &&
    userDetails?.address?.AddressLine1 && userDetails?.address?.City && userDetails?.address?.PostalCode
    && userDetails?.address?.Country;

  const isLastTabValid = !errorMessage.categories && !errorMessage.languages;

  const handleImageChange = (newImage) => {
    setUserDetails(prevState => ({ ...prevState, creatorPhoto: newImage }));
  }
  const handleChangeTab = (index) => {
    const validations = [isLastTabValid, isFirstTabValid];
    if (validations[index]) {
      setCurrentTab(index + 1);
    }
  };

  return (
    <div className='creator-account creator-account-setup'>
      <div className='title-wrap'>
        <p className='title'>
          Account Set-Up
        </p>
        <div className='creatorProfileButtonsWrapper account-setup'>
          <Button className='creatorProfileButton' onClick={() => handleSwitch()}>
            <LoopIcon />
            {creator?.type === UserType.Creator ? 'Switch To Agency' : 'Switch to creator'}
          </Button>
          {isEditProfile && <Button className='creatorProfileButton' onClick={() => handleGoBack()}>
            Go Back
          </Button>}
        </div>
      </div>
      <div className='welcome-wrap'>
        <p className='welcome-title'>Welcome to Gameinfluencer!</p>
        <p className='welcome-info'>We are thrilled to have you join our community of amazing influencers. To help brands get to know you better,
          we need some information to complete your profile. Please take a few moments to fill in the following details:</p>
      </div>
      <div className='tabLayer'>
        {tabItems.map((item, index) =>
          <div
            key={index}
            className={`${(item.index === currentTab) ? "current" : ""} tabLayerItem`}
            onClick={() => handleChangeTab(index)}
          >
            <div
              className={`${(item.index === currentTab) ? "selected" : ""} tabNumber`}
            >
              0{item.index}
            </div>
            <span className='tabName'>{item.name}</span>
            {(item.index === currentTab) && <RedTriangle />}
          </div>)}
      </div>
      {isLoading ? <CustomLoader /> :
        <div className='tab-content-wrap'>
          {currentTab === TabStep.influencerInformation && <div className='add-influencer-info' >
            <div className='info-content'>
              <div className='info-content-main'>
                <div className='profile-image-wrap'>
                  <ImageUploader
                    currentTab={1}
                    coverImage={userDetails.creatorPhoto || noImage}
                    onImageChange={handleImageChange}
                    isCreatorProfile={true}
                  />
                </div>
                <div className='profile-info-wrap'>
                  <div className="first-last-wrap">
                    <div className="input-wrap">
                      <p className='label'>First Name <span className="required">*</span></p>
                      <InputText value={userDetails.firstName} placeholder='First Name' className='inputField' onChange={(e) => handleChange('firstName', e.target.value)} disabled={creator.firstName} />
                      <div className='registerError' style={{ minHeight: 20 }}>{errorMessage.firstName && <p>{errorMessage.firstName}</p>}</div>
                    </div>
                    <div className="input-wrap">
                      <p className='label'>Last Name <span className="required">*</span></p>
                      <InputText value={userDetails.lastName} placeholder='Last Name' className='inputField' onChange={(e) => handleChange('lastName', e.target.value)} disabled={creator.lastName} />
                      <div className='registerError' style={{ minHeight: 20 }}>{errorMessage.lastName && <p>{errorMessage.lastName}</p>}</div>
                    </div>
                  </div>
                  <div className="first-last-wrap">
                    <div className="input-wrap">
                      <p className='label'>Username <span className="required">*</span></p>
                      <InputText value={userDetails.creatorName} placeholder={`${creator.type == "creator" ? 'creatorName' : 'AgencyName'}`}
                        className='inputField' onChange={(e) => handleChange('creatorName', e.target.value)} disabled={creator.type == "creator" ?
                          creator.name : creator?.agencyData?.name} />
                      <div className='registerError' style={{ minHeight: 20 }}>{errorMessage.creatorName && <p>{errorMessage.creatorName}</p>}</div>
                    </div>
                    <div className="input-wrap">
                      <p className='label'>Email <span className="required">*</span></p>
                      <InputText value={userDetails.email} placeholder='Email' className='inputField' onChange={(e) => handleChange('email', e.target.value)} disabled={creator.email} />
                      <div className={(errorMessage.email) ? 'registerError' : 'helperText'} style={{ minHeight: 20 }}>
                        <p>{(errorMessage.email) ? errorMessage.email : `Please make sure that the same email address is your active 
                        email account.`}</p>
                      </div>
                    </div>
                  </div>
                  <div className="first-last-wrap">
                    <div className="input-wrap">
                      <p className='label'>Date of birth <span className="required">*</span></p>
                      <Calendar value={userDetails.dateOfBirth ? new Date(userDetails.dateOfBirth) : ""} icon={<WhitelistDateIcon />} iconPos='right' showIcon onChange={(e) => {
                        handleChange('dateOfBirth', e.target.value);
                      }}
                        selectionMode="single"
                        showButtonBar
                        className='daterange'
                        placeholder='Date of birth'
                        hideOnRangeSelection
                        maxDate={new Date()}
                      />
                      <div className='registerError' style={{ minHeight: 20 }}>{errorMessage.dateOfBirth && <p>{errorMessage.dateOfBirth}</p>}</div>
                    </div>
                    <div className="input-wrap dropdown-wrap">
                      <p className='label'>Country of residency <span className="required">*</span></p>
                      <Dropdown options={countryOptions}
                        onChange={
                          (e) => {
                            handleChange('countries', e.target.value);
                            const selectedCountry = countryOptions.find(option => option.value === e.target.value);
                            setUserDetails((prev) => ({ ...prev, address: { ...prev.address, Country: selectedCountry?.code } }));
                          }
                        }
                        value={userDetails.countries}
                        optionLabel="name" placeholder="Select a country"
                        filter
                        valueTemplate={selectedOptionTemplate}
                        itemTemplate={dropdownOptionTemplate}
                        showClear={false}
                        disabled={false}
                        className="w-full md:w-14rem"
                        dropdownIcon={<WhitelistDropdownIcon scale={0.6} />}
                        panelClassName="account-setup-country-dropdown-panel"
                      />
                      <div className='registerError' style={{ minHeight: 20 }}>{errorMessage.countries && <p>{errorMessage.countries}</p>}</div>
                    </div>
                  </div>
                  <div className="input-wrap">
                    <p className='label'>Address <span className="required">*</span></p>
                    <InputText value={userDetails.address.AddressLine1} onChange={(e) => setUserDetails((prev) => ({ ...prev, address: { ...prev.address, AddressLine1: e.target.value } }))}
                      className='inputField' autoResize placeholder="Add your address here" />
                  </div>
                  <div className="input-wrap">
                    <p className='label'>City <span className="required">*</span></p>
                    <InputText value={userDetails.address.City} onChange={(e) => setUserDetails((prev) => ({ ...prev, address: { ...prev.address, City: e.target.value } }))}
                      className='inputField'
                      autoResize placeholder="Add your city" />
                  </div>
                  <div className="input-wrap">
                    <p className='label'>Postal Code <span className="required">*</span></p>
                    <InputText value={userDetails.address.PostalCode}
                      onChange={(e) => setUserDetails((prev) => ({ ...prev, address: { ...prev.address, PostalCode: e.target.value } }))}
                      className='inputField'
                      autoResize placeholder="Add your postal code" />
                  </div>
                  <div className="input-wrap">
                    <p className='label'>About you</p>
                    <InputTextarea value={userDetails.creatorDescription} onChange={(e) => handleChange('creatorDescription', e.target.value)} className='inputField' rows={6} maxLength={200} autoResize placeholder="Add about info" />
                  </div>
                </div>
              </div>
            </div>
            <div className='buttonLayer'>
              <Button className='saveButton' onClick={() => setCurrentTab(2)} disabled={!isFirstTabValid} >Save And Continue</Button>
            </div>
            <NewImageCropper visible={openCropper} onHide={() => setOpenCropper(false)} image={userDetails.creatorPhoto} setCroppedPhoto={(croppedPhoto) => handleChange('creatorPhoto', croppedPhoto)} />
          </div>}
          {currentTab === TabStep.channelInformation && <div className='add-channel-info add-influencer-info' >
            <div className='content-category-wrap'>
              <div className="input-wrap dropdown-wrap">
                <p className='label'>Categories <span className="required">*</span></p>
                <p>Please select up to 3 genres which best represent the type of content you create.</p>
                <MultiSelect options={contentCategoryOptions.map(option => { return { ...option, disabled: userDetails?.categories?.length >= 3 } })}
                  onChange={
                    (e) => {
                      handleChange('categories', e.target.value);
                    }
                  }
                  optionLabel="name" placeholder="Select a content category"
                  filter
                  value={userDetails?.categories}
                  valueTemplate={selectedOptionTemplate}
                  itemTemplate={dropdownOptionTemplate}
                  className="w-full md:w-14rem"
                  display="chip"
                  dropdownIcon={<WhitelistDropdownIcon scale={0.6} />} />
                <div className='registerError' style={{ minHeight: 20 }}>{errorMessage.categories && <p>{errorMessage.categories}</p>}</div>
              </div>
            </div>
            <div className='content-language-wrap'>
              {(creator.type === UserType.Creator) ?
                <div className="input-wrap dropdown-wrap">
                  <p className='label'>Language of content <span className="required">*</span></p>
                  <p>Select your content language.</p>
                  <Dropdown options={languageList}
                    onChange={
                      (e) => {
                        handleChange('languages', e.target.value);
                      }
                    }
                    optionLabel="name" placeholder="Select a content language."
                    filter
                    value={userDetails?.languages}
                    valueTemplate={selectedOptionTemplate}
                    itemTemplate={dropdownOptionTemplate}
                    showClear={false}
                    disabled={false}
                    className="w-full md:w-14rem"
                    dropdownIcon={<WhitelistDropdownIcon scale={0.6} />} />
                  <div className='registerError' style={{ minHeight: 20 }}>{errorMessage.languages && <p>{errorMessage.languages}</p>}</div>
                </div> :
                <div className="input-wrap dropdown-wrap">
                  <p className='label'>Language of content</p>
                  <p>Select your content language.</p>
                  <MultiSelect options={languageList}
                    onChange={
                      (e) => {
                        handleChange('languages', e.target.value);
                      }
                    }
                    optionLabel="name" placeholder="Select a content language"
                    filter
                    value={userDetails?.languages}
                    valueTemplate={selectedOptionTemplate}
                    itemTemplate={dropdownOptionTemplate}
                    className="w-full md:w-14rem"
                    display="chip"
                    dropdownIcon={<WhitelistDropdownIcon scale={0.6} />} />
                  <div className='registerError' style={{ minHeight: 20 }}>{errorMessage.languages && <p>{errorMessage.languages}</p>}</div>
                </div>
              }
            </div>
            {creator?.type === UserType.Creator && <div className="social-links-wrap">
              <p className='label'>Platforms</p>
              <p>Add links to your profiles.</p>
              <div className='urlsWrapper'>
                <div>
                  <div className='otherUrlsWrapper'>
                    {userDetails?.platforms?.map((item, index) =>
                      <div key={item.plateform} className='linkWrapper'>
                        < div className='icon-wrap'>
                          <LinkFieldIcon index={index} />
                          <p className='label'>{item.platform}</p>
                        </div>
                        <div className='link-input-wrap'>
                          {
                            item.value.map((link, linkIndex) => {
                              return (
                                <div key={linkIndex} className='link-input-inner-wrap'>
                                  <InputText placeholder={`Enter your ${item.platform} URL`}
                                    className='urlInput inputField' value={link} onChange={(e) => handleUrlChange(e, index, linkIndex)} />
                                  {<RedX onClick={() => handleRemoveUrl(index, linkIndex)} />}
                                </div>
                              )
                            })
                          }
                        </div>
                        <div className='add-url-wrap' title={`Add More ${item.platform} URL`} onClick={() => handleAddUrl(index)}>
                          <AddNew />
                        </div>
                      </div>)}
                  </div>
                </div>
              </div>
            </div>}
            <div className='buttonLayer'>
              <Button className='saveButton' onClick={() => handleSubmit()} disabled={!isLastTabValid}>Save And Finish</Button>
            </div>
          </div>}
        </div>}
      <Dialog visible={openAgencyDialog} onHide={() => setOpenAgencyDialog(false)} className='agencyDialog' >
        <div className='agencyDialogClose'>
          <div onClick={() => setOpenAgencyDialog(false)}><CloseIcon /></div>
        </div>
        <div className='agencyDialogContent'>
          <p className='agencyDialogTitle'>Learn More About Becoming an Agency</p>
          <p className='agencyDialogText'>Build the community of influencers, apply to campaigns together and double your chances for success.</p>
          <div className='agencyDialogIconWrapper'>
            <StarDocumentIcon />
          </div>
          <Divider className='agencyDialogDivider' />
          <div className='agencyDialogReasoning'>
            <div>
              <p className='agencyRedText'>More Campaigns, More Opportunities</p>
              <p className='agencyDialogText textLeft'>With an agency account you can invite other influencers and apply to campaigns together. You can get an access to the agency statistics and more.</p>
            </div>
            <div>
              <p className='agencyRedText'>Build a Community</p>
              <p className='agencyDialogText textLeft'>Create a community of influencers that have the same interests and chase opportunities together.</p>
            </div>
          </div>
          <Button className='becomeAnAgencyButton' onClick={() => switchAccount()} >Become an agency</Button>
        </div>
      </Dialog>
    </div>
  )
}
