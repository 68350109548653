import React, { useMemo } from 'react';
import { Chart } from 'primereact/chart';

export const RemainingBudgetCharts = ({ budget, totalPayoutCap }) => {
  // budget.assignBudget = 0;
  const totalBudget = budget?.totalBudget || 0;
  // const assignedBudget = budget?.assignBudget || 0;
  const totalAssigned = totalPayoutCap;

  const clampedAssignedPercentage = Math.max((totalAssigned / totalBudget) * 100, 0);
  const clampedRemainingPercentage = Math.max(100 - clampedAssignedPercentage, 0);

  const dataRemaining = useMemo(() => ({
    datasets: [
      {
        data: [
          Math.max(totalBudget - totalAssigned, 0),
          Math.min(totalAssigned, totalBudget),
        ],
        backgroundColor: ['#E73D38', 'lightgray'],
        borderWidth: 0,
      },
    ],
  }), [totalBudget, totalAssigned]);

  const dataAssigned = useMemo(() => ({
    datasets: [
      {
        data: [
          Math.min(totalAssigned, totalBudget),
          Math.max(totalBudget - totalAssigned, 0),
        ],
        backgroundColor: ['#E73D38', 'lightgray'],
        borderWidth: 0,
      },
    ],
  }), [totalBudget, totalAssigned]);

  const options = {
    cutout: '70%',
    plugins: { legend: { display: false } },
    interaction: { mode: false, intersect: false },
  };

  return (
    <>
      <div className='chartWrapper'>
        <Chart type="doughnut" data={dataAssigned} options={options} className='chartGraphic' id='Assigned' />
        <div className='chartPercentage'>{Math.round(clampedAssignedPercentage)}%</div>
        <div className='budgetText'>
          <p className='budgetLabel'>Budget Assigned</p>
          <p className='budgetValue'>${totalAssigned.toFixed(2)}</p>
        </div>
      </div>
      <div className='chartWrapper'>
        <Chart type="doughnut" data={dataRemaining} options={options} className='chartGraphic' id='Remaining' />
        <div className='chartPercentage'>{Math.round(clampedRemainingPercentage)}%</div>
        <div className='budgetText'>
          <p className='budgetLabel'>Budget Remaining</p>
          <p className='budgetValue'>${Math.max(totalBudget - totalAssigned, 0).toFixed(2)}</p>
        </div>
      </div>
    </>
  );
};

export default RemainingBudgetCharts;
