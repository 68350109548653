import React, { useEffect, useState } from 'react';

import { Button } from "primereact/button";
import { SelectButton } from 'primereact/selectbutton';

import NewLoginForm from "@/react_app/NewAuth/NewLoginForm/NewLoginForm";
import NewLoginSocials from "@/react_app/NewAuth/NewLoginSocials/NewLoginSocials";
import NewRestorePasswordDialog from "@/react_app/NewAuth/NewRestorePasswordDialog/NewRestorePasswordDialog"

import { TYPE_OPTIONS } from '@/utils/globals/constants';

import { CheckedIcon, NotCheckedIcon, TipIcon } from '@/react_app/img/Icons';

import "./NewLoginFull.css";

export default function (props) {

  const [userType, setUserType] = useState('');
  const [loginStep, setLoginStep] = useState(1);
  const [userDetails, setUserDetails] = useState({ email: '', password: '' });
  const [visibleRestorePassword, setVisibleRestorePassword] = useState(false);

  const handleSignIn = () => {
    if (props.signIn) {
      props.signIn({ email: userDetails.email, password: userDetails.password });
    } else if (props.register) {
      props.register({ email: userDetails.email, password: userDetails.password });
    }
  };
  const handleChange = (key, value) => {
    setUserDetails((prev) => ({ ...prev, [key]: value }));
  };
  const renderOptionItem = (option) => (
    <div className='optionItem'>
      {userType?.value === option.value ? <CheckedIcon /> : <NotCheckedIcon />}
      {option.label}
    </div>
  );
  const handleSubmit = () => {
    setLoginStep(2);
  }
  const handleUserType = (event) => {
    setUserType(TYPE_OPTIONS.find(item => item.value === event.value))
    localStorage.setItem('login-view-Type', event.value == "brand" ? 'brandOwner' : 'creator');
  }

  useEffect(() => {
    const type = localStorage.getItem('login-view-Type');
    const selectedType = (type) ? type === 'brandOwner' ? 'brand' :  'influencer' : '';
    setUserType(TYPE_OPTIONS.find(item => item.value === selectedType) || '');
    return () => {
      localStorage.removeItem('login-view-Type')
    }
  }, [])

  return (
    <div className='login-full'>
      <p className='loginText'>Login</p>
      {loginStep === 1 ?
        <>
          <p className='bottomText'>Select your preferred profile type for system login.</p>
          <SelectButton id="selectButton" value={userType?.value} onChange={(e) => handleUserType(e)}
            options={TYPE_OPTIONS} optionLabel="label" itemTemplate={renderOptionItem} />
          {userType &&
            <div className='loginType'>
              <div className='loginTypeTitle'>
                <TipIcon />
                <span className='loginTypeBigText'>{`${userType.label} Login`}</span>
              </div>
              <p className='loginTypeSmallText'>{userType.title}</p>
              <p className='loginTypeSmallText'>{userType.subTitle}</p>
            </div>
          }
          <Button className='signInButton' disabled={!userType} onClick={() => handleSubmit()}>Continue</Button>
          <div className='centerText'>
            <p className='newAccountText'>Don’t have an account?</p>
            <p className='redUnderlineTextCapital' onClick={() => props.handleRouting('auth/register')}>Register</p>
          </div>
        </>
        : <>
          <NewLoginSocials action='login' onConnect={props.handleUserConnect} userType={userType?.value} connecting={props.connecting} />
          <NewLoginForm handleChange={handleChange} />
          <Button label={"SIGN IN"} className='signInButton' onClick={handleSignIn} />
          <p className='forgotPasswordText'>Forgot your password? <span className='redUnderlineText' onClick={() => setVisibleRestorePassword(true)}>Restore</span></p>
          <p className='newAccountText'>Don’t have an account?</p>
          <p className='redUnderlineTextCapital' onClick={() => { props.handleRouting('auth/register') }}>Register</p>
          <NewRestorePasswordDialog visible={visibleRestorePassword} onHide={() => setVisibleRestorePassword(false)} />
        </>
      }

    </div>
  )
}
