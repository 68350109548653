import React, { useMemo } from 'react';
import { Chart } from 'primereact/chart';
import { ClientMaxCap } from '@/react_app/NewAdmin/WhiteListing/charts';

const ViewChart = ({ campaign, currentCampaignBudget }) => {
    const totalPaidViews = Math.round((campaign?.budget?.amount / campaign?.cpm?.amount) * 1000);
    const viewCount = currentCampaignBudget?.viewCount  || 0;

    const viewPercentage = Math.ceil((viewCount / totalPaidViews) * 100);

    const dataMain = useMemo(() => ({
        datasets: [{
            data: [
                Math.max(totalPaidViews - viewCount, 0), // Remaining views
                Math.min(viewCount, totalPaidViews), // Approved views
            ],
            backgroundColor: ['#E73D38', 'lightgray'],
            borderWidth: 0,
        }],
    }), [viewCount, totalPaidViews]);

    const optionsMain = {
        cutout: '70%',
        plugins: { legend: { display: false } },
        interaction: { mode: false, intersect: false },
    };

    return (
        <>
            <div className="chartWrapper">
                <Chart type="doughnut" data={dataMain} options={optionsMain} className="chartGraphic" />
                <div className="chartPercentage">{viewPercentage}%</div>
                <div className="info">
                    <span className="label">Projected Views - Approved</span>
                    <span className="value">{`${viewCount?.toLocaleString()} views`}</span>
                </div>
            </div>
            <ClientMaxCap amount={currentCampaignBudget?.cpm?.payoutCap || 0} />
        </>
    );
};

export default ViewChart;
