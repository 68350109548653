import React, { useEffect } from 'react';

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

import { useAuth } from '@/modules';
import { getUTCTimestampFromDate } from '@/utils/globals/date-utils';
import { countries, gigapaySupportedCountries } from '@/data/static/countries';

import { CloseIcon } from "@/react_app/img/Icons";

import './NewPaymentInfoDialog.css';

const schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required').email("Please enter a valid email address"),
  countryOfResidence: Yup.object().nullable().required('Country of residence is required'),
  iban: Yup.string().required('IBAN/SWIFT is required'),
});

export default function NewPaymentInfoDialog(props) {

  const { paymentInfoChange, hide } = props;
  const user = useAuth();
  const {
    control, handleSubmit, reset, watch, formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      isLegalUser: false,
      legalPersonType: '',
      firstName: '',
      lastName: '',
      dateOfBirth: new Date(),
      nationality: null,
      countryOfResidence: null,
      addressLine: '',
      city: '',
      postalCode: '',
      countryAddress: null,
      iban: '',
    },
  });

  const isLegal = watch('isLegalUser');

  useEffect(() => {
    if (props.currentPaymentInfo) {
      reset({
        isLegalUser: props.currentPaymentInfo.PersonType === 'LEGAL',
        legalPersonType: props.currentPaymentInfo.LegalPersonType || '',
        firstName: props.currentPaymentInfo.FirstName || '',
        lastName: props.currentPaymentInfo.LastName || '',
        dateOfBirth: new Date(props.currentPaymentInfo.Birthday) || new Date(),
        nationality: countries.find(item => item.code === props.currentPaymentInfo.Nationality) || null,
        countryOfResidence: countries.find(item => item.code === props.currentPaymentInfo.CountryOfResidence) || null,
        addressLine: props.currentPaymentInfo.LegalRepresentativeAddress?.AddressLine1 || '',
        city: props.currentPaymentInfo.LegalRepresentativeAddress?.City || '',
        postalCode: props.currentPaymentInfo.LegalRepresentativeAddress?.PostalCode || '',
        countryAddress: countries.find(item => item.code === props.currentPaymentInfo.LegalRepresentativeAddress?.Country) || null,
        iban: props.currentPaymentInfo.IBAN || '',
        email: props.currentPaymentInfo.Email
      });
    } else {
      reset({ email: user?.user?.value?.email })
    }
  }, [props.currentPaymentInfo, reset]);

  const onSubmit = (data) => {
    const payload = {
      PersonType: data.isLegalUser ? 'LEGAL' : 'NATURAL',
      LegalRepresentativeAddress: {
        AddressLine1: data.addressLine,
        City: data.city,
        PostalCode: data.postalCode,
        Country: data.countryAddress?.code,
      },
      IBAN: data.iban,
      Birthday: getUTCTimestampFromDate(data.dateOfBirth),
      CountryOfResidence: data.countryOfResidence?.code,
      Nationality: data.nationality?.code,
      FirstName: data.firstName,
      LastName: data.lastName,
      Email: data.email
    };

    if (data.isLegalUser) {
      payload.LegalPersonType = data.legalPersonType;
    }
    paymentInfoChange(payload);
    hide();
  };

  return (
    <Dialog visible={props.visible} onHide={() => hide()} className='payment-info-modal'>
      <div className='paymentInfoClose'>
        <div onClick={() => hide()}><CloseIcon /></div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='paymentInfoMain'>
        <p className='paymentInfoTitle'>Payment Information</p>
        <div className='dialogSwitch'>
          <span className={!isLegal ? 'dialogSwitchTextRed' : 'dialogSwitchTextGrey'}>Natural User</span>
          <Controller name="isLegalUser" control={control} render={({ field }) => (
            <InputSwitch {...field} checked={field.value} onChange={(e) => field.onChange(e.value)} />)}
          />
          <span className={isLegal ? 'dialogSwitchTextRed' : 'dialogSwitchTextGrey'}>Legal User</span>
        </div>
        <div className='dialogSubField'>
          <p className='subFieldTitle'>Payment Information</p>
          {isLegal && (
            <div className='fieldWrapper'>
              <p className='subFieldLabel'>Legal Person Type</p>
              <Controller name="legalPersonType" control={control} render={({ field }) => (
                <InputText {...field} className='subFieldInput' />)}
              />
              {errors.legalPersonType && <p className="errorMessage">{errors.legalPersonType.message}</p>}
            </div>
          )}
          <div className='fieldWrapper'>
            <p className='subFieldLabel'>Email<span className='requiredSign'>&nbsp;*</span></p>
            <Controller name="email" control={control} render={({ field }) => (
              <InputText {...field} className='subFieldInput' aria-describedby="username-help" />)}
            />
            {!errors.email && <small id="username-help" className='helperText'>
              {/* Please make sure that the same email address is linked with your Gigapay account. */}
              Your email address will be used to complete the setup process and facilitate payouts through&nbsp;
              <a href='https://app.gigapay.se/login' target='_blank' style={{ textDecoration: 'underline' }}>Gigapay,</a>&nbsp;
              our payment provider. Once you submit this form, you will receive a confirmation email at this address. Please ensure the email provided is correct, as it is essential for receiving payments.
            </small>}
            {errors.email && <p className="errorMessage">{errors.email.message}</p>}
          </div>
          <div className='fieldWrapper'>
            <p className='subFieldLabel'>IBAN/SWIFT<span className='requiredSign'>&nbsp;*</span></p>
            <Controller name="iban" control={control} render={({ field }) => (
              <InputText {...field} className='subFieldInput' />)}
            />
            {errors.iban && <p className="errorMessage">{errors.iban.message}</p>}
          </div>
          <div className='fieldWrapper'>
            <p className='subFieldLabel'>{isLegal && 'Legal representative'}First name
              <span className='requiredSign'>&nbsp;*</span></p>
            <Controller name="firstName" control={control} render={({ field }) => (
              <InputText {...field} className='subFieldInput' />)}
            />
            {errors.firstName && <p className="errorMessage">{errors.firstName.message}</p>}
          </div>
          <div className='fieldWrapper'>
            <p className='subFieldLabel'>{isLegal && 'Legal representative'} Last name
              <span className='requiredSign'>&nbsp;*</span>
            </p>
            <Controller name="lastName" control={control} render={({ field }) => (
              <InputText {...field} className='subFieldInput' />)}
            />
            {errors.lastName && <p className="errorMessage">{errors.lastName.message}</p>}
          </div>
          <div className='fieldWrapper'>
            <p className='subFieldLabel'>{isLegal && 'Legal representative'} Date of birth</p>
            <Controller name="dateOfBirth" control={control} render={({ field }) => (
              <Calendar {...field} className='subFieldCalendar' showIcon iconDisplay="input" />)}
            />
          </div>
          <div className='fieldWrapper'>
            <p className='subFieldLabel'>{isLegal && 'Legal representative'}Nationality</p>
            <Controller name="nationality" control={control} render={({ field }) => (
              <Dropdown {...field} filter className='subFieldDropdown' optionLabel="name" options={countries}
                placeholder="Select a Nationality" />
            )}
            />
          </div>
          <div className='fieldWrapper'>
            <p className='subFieldLabel'>{isLegal && 'Legal representative'}Country of Residence</p>
            <Controller name="countryOfResidence" control={control} render={({ field }) => (
              <Dropdown {...field} filter className='subFieldDropdown' optionLabel="name" options={gigapaySupportedCountries}
                placeholder="Select a country of residence" />
            )}
            />
            {errors.countryOfResidence && <p className="errorMessage">{errors.countryOfResidence.message}</p>}
          </div>
        </div>
        <div className='dialogSubField'>
          <p className='subFieldTitle'>Address</p>
          <div className='fieldWrapper'>
            <p className='subFieldLabel'>Address line 1</p>
            <Controller name="addressLine" control={control} render={({ field }) => (
              <InputText {...field} className='subFieldInput' />)}
            />
          </div>
          <div className='fieldWrapper'>
            <p className='subFieldLabel'>City</p>
            <Controller name="city" control={control} render={({ field }) => (
              <InputText {...field} className='subFieldInput' />)}
            />
          </div>
          <div className='fieldWrapper'>
            <p className='subFieldLabel'>Postal code</p>
            <Controller name="postalCode" control={control} render={({ field }) => (
              <InputText {...field} className='subFieldInput' />)}
            />
          </div>
          <div className='fieldWrapper'>
            <p className='subFieldLabel'>Country</p>
            <Controller name="countryAddress" control={control} render={({ field }) => (
              <Dropdown {...field} filter className='subFieldDropdown' optionLabel="name" options={countries}
                placeholder="Select a country" />
            )}
            />
          </div>
        </div>
        <Button type="submit" className='submitPaymentInfoButton'>Submit info</Button>
      </form>
    </Dialog>
  );
}
