import React from 'react'

import { Button } from 'primereact/button';

import { useAuth } from '@/modules';
import { ExclamationIcon } from '@/react_app/NewAdmin/img/Icons';

import './StickyToast.css';

const GigaPayToast = (props) => {

    const { gotoProfile } = props;
    const user = useAuth();

    return (
        <div className="stickyToast">
            <div className='headerContainer'>
                <ExclamationIcon />
                <span className="title">Update your payment info</span>
            </div>
            <div>
                <p className='subTitle'>Add the <a href='https://app.gigapay.se/login' target='_blank' style={{ textDecoration: 'underline' }}>Gigapay</a>&nbsp;
                    details in your payment info to get paid in time!</p>
            </div>
            <div className='actionButton'>
                <Button className="update-button" onClick={() => gotoProfile(`creators/${user?.user?.value?.id}/account`,'paymentInfo')}>Update</Button>
            </div>
        </div>

    )
}

export default GigaPayToast