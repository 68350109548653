import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewCreatorAccount = _resolveComponent("NewCreatorAccount")!
  const _component_OldCreatorAccount = _resolveComponent("OldCreatorAccount")!

  return ($setup.isCreatorAccountEnabled)
    ? (_openBlock(), _createBlock(_component_NewCreatorAccount, {
        key: 0,
        creator: $setup.creator,
        notificationsForUser: $setup.notificationsForUser,
        notificationsMethods: $setup.notificationsMethods,
        channelsYT: $setup.channels?.channel?.items,
        openConnectPopup: $setup.openConnectPopup,
        paymentInfoChange: $setup.paymentInfoChange,
        user: $setup.user,
        updateCreator: $setup.updateCreator,
        currentPaymentInfo: $setup.currentPaymentInfo,
        content: $setup.latestContent,
        handleContentRouting: $setup.handleContentRouting,
        switchAccount: $setup.switchAccount,
        loadChannels: $setup.loadChannels,
        handleAccountSetupRouting: $setup.handleAccountSetupRouting,
        queryParams: $setup.queryParams
      }, null, 8, ["creator", "notificationsForUser", "notificationsMethods", "channelsYT", "openConnectPopup", "paymentInfoChange", "user", "updateCreator", "currentPaymentInfo", "content", "handleContentRouting", "switchAccount", "loadChannels", "handleAccountSetupRouting", "queryParams"]))
    : (_openBlock(), _createBlock(_component_OldCreatorAccount, {
        key: 1,
        viewPerspective: $props.viewPerspective
      }, null, 8, ["viewPerspective"]))
}