import React, { useEffect, useState } from "react";
import "./NewSubmitContent.css";
import {
  CloseIcon, CopyIcon, CopyIconSmall, DesktopIcon, LinkIcon, LinkIconContent, TrashIcon, CopyIconMenu, ExpandArrowDown, ExpandArrowUp
} from "@/react_app/img/Icons";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { CampaignPaymentMethod, UserType } from "@/data/types";
import { useAuth, useContentItems, useCreators, useToastModule } from "@/modules";
import { useCampaignContracts } from "@/modules/campaigns/campaign-contracts-module";
import { useCampaignDeliverables } from "@/modules/campaigns/campaign-deliverables-module";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { formatDate } from "@/utils/globals/date-utils";
import UGCVideoDialog from '../components/Dialogs/UGCVideoDialog';
import noImage from "../../../src/react_app/img/no-image-1-gray.png";
import { PUBLIC_VIDEO_MESSAGE } from '@/utils/globals/constants';

const videoTemplate = (rowData) => {
  return (
    <div className={'videoTemplate'}>
      <Image src={rowData?.thumbnails?.default?.url} alt={'video thumbnail'} className={'videoThumbnail'} />
      <p className={'videoTitle'}>{rowData.title}</p>
    </div>
  )
};

const urlTemplate = (rowData) => {
  return (
    <div className={'urlTemplate'}>
      <p className={'urlLink'}>{rowData?.url}</p>
      <div onClick={() => navigator.clipboard.writeText(rowData?.url)}>
        <CopyIconSmall />
      </div>
    </div>
  )
};

const statusTemplate = () => {
  return (
    <div className={'statusTemplate'}>
      Uploaded
    </div>
  )
};

const dateTemplate = (rowData) => {
  return (
    <div className={'dateTemplate'}>
      {formatDate(rowData.createdAt || rowData?.aspectRatios[0]?.submittedAt)}
    </div>
  )
};

const deleteTemplate = () => {
  return (
    <div className={'deleteTemplate'}>
      <TrashIcon />
    </div>
  )
};

const conceptTemplate = (rowData) => {
  return (
    <div className={'conceptTitleWrap'}>
      <p className={'conceptTitle'} style={{ height: 'auto' }}>Concept</p>
      <p className={'conceptValue'} style={{ height: 'auto' }}>{rowData.concept}</p>
    </div>
  )
};

export default function (props) {
  const { isUGCCampaign, latestUGCSubmits, getUGCUploadedContents } = props;
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openUGCUploadDialog, setOpenUGCUploadDialog] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState('');
  const [contract, setContract] = useState(undefined);
  const [dataActiveSubmission, setDataActiveSubmission] = useState(undefined);
  const [isLinkUploading, setIsLinkUploading] = useState(false);
  const [showBadChannelError, setShowBadChannelError] = useState(false);
  const [videoInfoError, setNoVideoInfoError] = useState(false);
  const [trackingLink, setTrackingLink] = useState('');
  const { user } = useAuth();
  const controller = useCreators();
  const content = useContentItems();
  const contractManager = useCampaignContracts(props.viewPerspective);
  const toast = useToastModule();
  const deliverablesManager = useCampaignDeliverables(props.viewPerspective);
  const [expandedRows, setExpandedRows] = useState([]);


  useEffect(() => {
    if (!!props) {
      setContract(props?.contract);
      setDataActiveSubmission(props?.contract?.contracts[0]?.deliverables[0])
    }
  }, [props?.contract]);

  useEffect(() => {
    if (user.value && props?.campaign) {
      if (user?.value?.isAgencyOwner()) {
        props?.campaign.trackingLinks.map((currentLink) => {
          if (currentLink.creatorId === user?.value?.id) {
            setTrackingLink(currentLink[0]?.link);
          }
          else if (user?.value?.agencyOwners?.some((agencyWorker) => agencyWorker?.channel?.channelId === currentLink?.channelId)) {
            setTrackingLink(currentLink[0]?.link);
          }
        });
      } else if (user?.value?.isCreator()) {
        props?.campaign.trackingLinks.map((currentLink) => {
          if (currentLink.creatorId === user?.value?.id) {
            setTrackingLink(currentLink[0]?.link || currentLink?.link);
          }
        });
      }
    }
  }, [props?.campaign]);

  const validateVideoPrivacy = (videosInfo) => {
    if (videosInfo?.status?.privacyStatus !== "public" && !props.route?.query?.draftAccepted) {
      toast.showError(PUBLIC_VIDEO_MESSAGE);
      setIsLinkUploading(false);
      return false;
    }
    return true;
  };

  const validateUserYouTubeChannel = () => {
    if (!user?.value.youtube?.length && user?.value.type === UserType.Creator) {
      toast.showError("Add your YouTube channel to your profile before uploading a video");
      setIsLinkUploading(false);
      return false;
    }
    return true;
  };

  const showErrorAndSetFlags = () => {
    toast.showError("Error adding video");
    setShowBadChannelError(true);
    setIsLinkUploading(false);
  }

  const validateChannelChecking = (videosInfo) => {
    if (contract?.paymentMethod !== CampaignPaymentMethod.CPM) {
      return true;
    }

    if (user?.value.agencyWorkers?.length && user?.value.type === UserType.Agency) {
      const isChannelIndex = user?.value.agencyWorkers.findIndex((item) => item?.channel?.channelId === videosInfo.channelId);
      if (isChannelIndex < 0) {
        showErrorAndSetFlags();
        return false;
      }
    } else if (user?.value.type === UserType.Creator && user?.value.youtube?.length) {
      const isChannelIndex = user?.value.youtube.findIndex((item) => item?.id && item?.id === videosInfo?.channelId);

      if (isChannelIndex < 0) {
        showErrorAndSetFlags();
        return false;
      }
    }

    return true;
  };

  const createContentItem = async (videosInfo) => {
    if (!videosInfo.vid) {
      setNoVideoInfoError(true);
      setIsLinkUploading(false);
      return false;
    }

    return await content.manager.createContent(user?.value.id, videosInfo, props.campaignID, props.selectedChannel);
  };

  const submitDeliverable = async (deliverableID, contentItem) => {
    if (!deliverableID) {
      await deliverablesManager.submit(contract?.id, contract?.contracts[0]?.id, {
        link: youtubeLink,
        contentItemId: contentItem?.id,
      });
    } else {
      await deliverablesManager.submit(contract?.id, contract?.contracts[0]?.id, {
        link: youtubeLink ? youtubeLink : "",
        deliverableID,
        contentItemId: contentItem?.id,
      });
    }
  };

  const loadContractData = async () => {
    await contractManager.loadSingle(props?.campaignID, props?.submissionID).then((data) => {
      setContract(data);
    });
  };
  const extractYouTubeID = () => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = youtubeLink.match(regex);
    return match ? [`&id=${match[1]}`] : null;
  };

  function validateAll(videoInfo) {
    const privacyValidation = validateVideoPrivacy(videoInfo);
    if (!privacyValidation) {
      return false;
    }

    const userChannelValidation = validateUserYouTubeChannel();
    if (!userChannelValidation) {
      return false;
    }

    return validateChannelChecking(videoInfo);
  }

  const fetchVideosInfo = async (userId) => {
    const IDs = extractYouTubeID();
    if (IDs) {
      const { videos } = await controller.youtube.getVideosInfo(userId, IDs);

      if (!videos || !videos.items || !videos.items.length) {
        return null;
      }

      return {
        publishedAt: videos.items[0]?.snippet?.publishedAt,
        title: videos.items[0]?.snippet.title,
        description: videos.items[0]?.snippet.description,
        thumbnails: videos.items[0]?.snippet.thumbnails,
        statistics: videos.items[0]?.statistics,
        channelId: videos.items[0]?.snippet?.channelId,
        url: youtubeLink,
        status: videos.items[0]?.status,
        vid: videos.items[0]?.id,
        photos: [
          videos.items[0]?.snippet.item?.thumbnails?.maxres?.url ||
          videos.items[0]?.snippet.item?.thumbnails?.standard.url ||
          videos.items[0]?.snippet.thumbnails?.high.url ||
          videos.items[0]?.snippet.thumbnails?.medium.url ||
          videos.items[0]?.snippet.thumbnails?.default.url,
        ],
      };
    } else {
      toast.showError("Invalid URL.");
      return false
    }
  };

  const sendLink = async (deliverableID) => {
    setIsLinkUploading(true);

    if (!user?.value) {
      return false;
    }

    if (contract?.paymentMethod === CampaignPaymentMethod.CPM && deliverableID) {
      toast.showError("You have already submitted a video.");
      setIsLinkUploading(false);
      return false;
    }
    const videosInfo = await fetchVideosInfo(user?.value.id, youtubeLink);
    if (!videosInfo || !validateAll(videosInfo)) {
      return false;
    }

    const contentItem = await createContentItem(videosInfo);

    if (!contentItem) {
      setYoutubeLink('');
      setIsLinkUploading(false);
      props?.setOpenCPMPendingModal(false);
      return false
    } else {

      if (contract && contract.length > 0) await submitDeliverable(deliverableID, contentItem);
      await loadContractData();
      setYoutubeLink('');
      setIsLinkUploading(false);
      props?.setOpenCPMPendingModal(false);
      props?.updateDataPage();
      return true;
    }
  };

  const postThroughModal = () => {
    sendLink(dataActiveSubmission?.id).then((success) => {
      if (success) {
        window.setTimeout(() => {
          setOpenUploadDialog(false);
          toast.showSuccess("Video Uploaded Successfully");
          if (!user?.value?.isAgencyOwner()) {
            if (props?.isCampaignCPM) {
              props?.handleChangeTab(4, true);
            } else {
              props?.handleChangeTab(2, true)
            }
          }
          if (user?.value?.isAgencyOwner()) {
            props?.getAgencyProgress()
          }
        }, 0);
      } else {
        setOpenUploadDialog(false);
        setIsLinkUploading(false)
      }
    }).catch(() => {
      setIsLinkUploading(false);
    });
  };

  const [progressCpmCampaign, setProgressCpmCampaign] = useState({
    videoDraft: false,
    draftAccepted: false,
    draftRejected: false,
    liveVideoPosted: false,
    rejectReason: "",
  });

  const handleUploadUGCPublish = async (res) => {
    window.setTimeout(() => {
      setOpenUGCUploadDialog(false);
      toast.showSuccess("Video Uploaded Successfully");
      if (!user?.value?.isAgencyOwner()) {
        props?.handleChangeTab(4, true);
      }
      if (user?.value?.isAgencyOwner()) {
        props?.getAgencyProgress()
      }
      getUGCUploadedContents("public");
      props?.updateDataPage();
    }, 100);
  }

  useEffect(() => {
    if (props && props?.progressCpmCampaign) {
      setProgressCpmCampaign(prevState => ({ ...prevState, ...props?.progressCpmCampaign }))
    }
  }, [props])

  const handleOpenDialog = () => {
    if (isUGCCampaign) setOpenUGCUploadDialog(true)
    else setOpenUploadDialog(true);
  }


  const expanderTemplate = (rowData) => {
    return (
      <div className='expanderTemplate'>
        {expandedRows.includes(rowData) ?
          <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
            <ExpandArrowUp />
          </div> :
          <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
            <ExpandArrowDown />
          </div>
        }
      </div>
    )
  };
  const rowExpansionTemplate = (rowData) => {
    const showFileSizeInMB = (bytes) => (bytes / (1024 * 1024))?.toFixed(1);

    return (
      <>
        <div className='darkLine' />
        <div className='expansionWrapper ugcUploadedContent'>
          <div className='expansionDataSquare'>
            <div className='video-wrap header'>
              <div className='ratio'>
                <p className='headerTitle'>Aspect Ratio</p>
              </div>
              <div className={`video-wrapper`}>
                <p className='headerTitle'>
                  Video Info
                </p>
              </div>
              <div className='videoInfoDate'>
                <p className='headerTitle'>Submission Date</p>
              </div>
              <div className='videoInfo videoStatus'>
                <p className='headerTitle'>Status</p>
              </div>
            </div>
            {rowData?.aspectRatios?.length > 0 ? <div className='request-info type-name'>
              {rowData?.aspectRatios?.map((ratio) => {
                return (
                  <div key={ratio?.ratio} className='video-wrap'>
                    <p className='ratio'>{ratio?.ratio}</p>
                    <div className={`video-wrapper`}>
                      <div className='video-preview'>
                        <div
                        >
                          <Image
                            src={noImage}
                            alt='file preview'
                            style={{ display: 'none' }}
                          />
                          <video
                            src={`${ratio?.videoUrl}#t=5`}
                            controls={false}
                            onError={(e) => {
                              e.target.style.display = 'none';
                              const img = e.target.previousElementSibling;
                              if (img) {
                                img.style.display = 'block';
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className='videoInfo'>
                        <div className={'urlTemplate'} style={{ marginBottom: '5px' }}>
                          <p className='file-name'>{ratio?.fileName || ""}</p>
                          <div onClick={() => navigator.clipboard.writeText(ratio?.videoUrl)}>
                            <CopyIconSmall />
                          </div>
                        </div >
                        <p className='file-size'>{showFileSizeInMB(ratio?.fileSize || 0)} MB</p>
                      </div>
                    </div>
                    <div className='videoInfoDate'>
                      <p className='ratio'>{ratio?.submittedAt || ""}</p>
                    </div>
                    <div className='videoInfo videoStatus'>
                      <p className={`file-status ${ratio?.status}`}>{ratio?.status}</p>
                    </div>
                  </div>
                )
              })}
            </div> :
              <p className='noUploadsFound'>No uploads found</p>
            }
          </div >
        </div >
      </>
    );
  };

  return (
    <div className={props.displayNone ? 'submit-content display-none' : 'submit-content'}>
      {props.selectedChannel && <p className={'channelName'}>Selected channel: {props.selectedChannel.channel.title}</p>}
      <div className={'topRow'}>
        <div className={'uploadSquare'}>
          <DesktopIcon />
          <p className={'uploadTitle'}>Upload content</p>
          <p className={'uploadText'}>{`${!isUGCCampaign
            ? 'Upload a URL of your published video.' :
            'Update the URLs of your final videos.'}`}</p>
          <Button className={'uploadButton'} disabled={isLinkUploading || progressCpmCampaign.liveVideoPosted} onClick={() => handleOpenDialog()} >{`${!isUGCCampaign ? 'Upload' : 'Update'}`} Video Url</Button>
          {!isUGCCampaign && <a className={'tutorialLink'} href={'https://www.youtube.com/watch?v=S6v1QZ675AI'} target={'_blank'}>Watch a step-by-step tutorial</a>
          }
        </div>
        {!isUGCCampaign && <div className={'linkAndGamekeys'}>
          <p className={'title'}>Your Influencer Link & Gamekeys</p>
          {trackingLink ? <p className={'text'}>Paste this link to your content in your channels to have access to insights and track progress.</p>
            :
            <p className={'text'}>No link and gamekey available</p>
          }

          {trackingLink &&
            <div className={'trackingLinkWrapper'}>
              <LinkIconContent />
              <span className={'trackingLink'} title={trackingLink}>{trackingLink}</span>
              <div onClick={() => navigator.clipboard.writeText(trackingLink)} className={'pointer'}>
                <CopyIconMenu scale={0.8} color={"#fff"} />
              </div>
            </div>
          }
          {contract?.gamekeys &&
            <div className={'trackingLinkWrapper'}>
              <LinkIcon />
              <span className={'trackingLink'} title={contract?.gamekeys}>{contract?.gamekeys}</span>
              <div onClick={() => navigator.clipboard.writeText(contract?.gamekeys)} className={'pointer'}>
                <CopyIcon />
              </div>
            </div>
          }
          <a className={'tutorialLink'} href={'https://www.youtube.com/watch?v=S6v1QZ675AI'} target={'_blank'}>Watch a step-by-step tutorial</a>
        </div>}
      </div>
      {!isUGCCampaign && <div>
        <p className={'uploadedContentTitle'}>Uploaded Content</p>
        <DataTable value={props?.latestSubmits}>
          <Column field="title" header="Video" body={videoTemplate} style={{ width: '45%' }}></Column>
          <Column field="url" header="Video Link" body={urlTemplate} style={{ width: '28%' }}></Column>
          <Column field="" header="Status" body={statusTemplate} style={{ width: '14%' }}></Column>
          <Column field="createdAt" header="Submission Date" body={dateTemplate} style={{ width: '14%' }}></Column>
          <Column field="" header="" body={deleteTemplate} style={{ width: '9%' }}></Column>
        </DataTable>
        <div className={'buttonGroup'}>
          <Button className={'leaveButton'} onClick={() => props.leaveCampaign()}>Leave Campaign</Button>
        </div>
      </div>}
      {isUGCCampaign && <div>
        <p className={'uploadedContentTitle'}>Uploaded Content</p>
        <DataTable value={latestUGCSubmits}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column field="concept" header="Concept" body={conceptTemplate} style={{ width: '95%', textAlign: 'left' }} align={'left'}></Column>
          <Column header="Action" expander={true} body={expanderTemplate} style={{ width: '5%' }} />
        </DataTable>
        <div className={'buttonGroup'}>
          <Button className={'leaveButton'} onClick={() => props.leaveCampaign()}>Leave Campaign</Button>
        </div>
      </div>}
      <Dialog visible={openUploadDialog || props.openSubmit} onHide={() => { setOpenUploadDialog(false); props.setOpenSubmit(false); }} className={'uploadDialog'}>
        <div className={'closeWrapper'}>
          <div onClick={() => { setOpenUploadDialog(false); props.setOpenSubmit(false); }}><CloseIcon /></div>
        </div>
        <div className={'uploadDialogContent'}>
          <p className={'uploadDialogTitle'}>Upload content</p>
          <p className={'youtubeLinkTitle'}>Your youtube link</p>
          <InputText className={'youtubeLinkInput'} placeholder={'Add a link to your YouTube video'} value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)} />
          <p className={'exampleLink'}>Example: https://www.youtube.com/watch?v=example/</p>
          {showBadChannelError &&
            <p className={'linkError'}>
              The video you are trying to download was uploaded by a YouTube channel that is not added to your profile.
              Please add your YouTube channel to your profile first.
            </p>
          }
          {videoInfoError &&
            <p className={'linkError'}>
              This video and the data from it are not available.
            </p>
          }
          <Button className={'uploadButton'} disabled={isLinkUploading || progressCpmCampaign.liveVideoPosted} onClick={() => postThroughModal()} loading={isLinkUploading}>Upload Video Url</Button>
          <p className={'warningText'}>By submitting your content to Gameinfluencer, you acknowledge that you agree to Gameinfluencer’s terms and conditions. please be sure not to violate others copyright or privacy rights. Learn more.</p>
        </div>
      </Dialog>
      <Dialog visible={props?.openCPMPendingModal} onHide={() => props.setOpenCPMPendingModal(false)} className={'uploadDialog'}>
        <div className={'closeWrapper'}>
          <div onClick={() => props.setOpenCPMPendingModal(false)}><CloseIcon /></div>
        </div>
        <div className={'uploadDialogContent'}>
          <p className={'uploadDialogTitle'}>Upload content</p>
          <p className={'youtubeLinkTitle'}>Your youtube link</p>
          <InputText className={'youtubeLinkInput'} placeholder={'Add a link to your YouTube video'} value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)} />
          <p className={'exampleLink'}>Example: https://www.youtube.com/watch?v=example/</p>
          {showBadChannelError &&
            <p className={'linkError'}>
              The video you are trying to download was uploaded by a YouTube channel that is not added to your profile.
              Please add your YouTube channel to your profile first.
            </p>
          }
          {videoInfoError &&
            <p className={'linkError'}>
              This video and the data from it are not available.
            </p>
          }
          <Button className={'uploadButton'} disabled={isLinkUploading || progressCpmCampaign.liveVideoPosted} onClick={() => postThroughModal()} loading={isLinkUploading}>Upload Video Url</Button>
          <p className={'warningText'}>By submitting your content to Gameinfluencer, you acknowledge that you agree to Gameinfluencer’s terms and conditions. please be sure not to violate others copyright or privacy rights. Learn more.</p>
        </div>
      </Dialog>
      {isUGCCampaign && <UGCVideoDialog
        open={user?.value?.isAgencyOwner() ? props.openCPMPendingUGCModal : openUGCUploadDialog}
        close={() => user?.value?.isAgencyOwner() ? props.setOpenCPMPendingUGCModal(false) : setOpenUGCUploadDialog(false)}
        conceptCount={props?.conceptCount}
        videoAspectRatio={props?.videoAspectRatio}
        currentType="publish"
        campaignId={props?.campaignID}
        handleUpload={handleUploadUGCPublish}
        latestUGCSubmits={latestUGCSubmits}
        selectedChannel={props.selectedChannel}
      />}
    </div>
  )
}
