
import { ref, watch } from "vue";
import {
  useCreators, usePage, useContentItems, useAuth, usePayments, useNotificationsMethods, useAgencys, useUsers,
  useToastModule
} from "@/modules";
import { AppPageConfig, EditUserPayload, User } from "@/data/types";
import { useRoute } from "vue-router";
import { applyPureReactInVue } from 'veaury'
import NewCreatorAccount from "@/react_app/NewCreatorAccount/NewCreatorAccount";
import OldCreatorAccount from "@/pages/creators/account/OldCreatorAccount.vue";
import { isCreatorAccountEnabled } from '@/utils/globals/feature-flags';
import { useSocketIo, useSocketMethods } from "@/services/socket";
import { openSocialPopup } from "@/utils/globals/popup-utils";
import { SocialConnectResponse } from "@/pages/auth/social-def";
import router from "@/router";
import { useToast } from 'primevue/usetoast';
import { POP_CLOSED_MESSAGE } from '@/utils/globals/constants';

export default {
  components: {
    NewCreatorAccount: applyPureReactInVue(NewCreatorAccount),
    OldCreatorAccount
  },
  props: {
    viewPerspective: String,
  },
  setup(props: any, ctx: any) {
    const { config } = usePage<AppPageConfig>({
      title: "account creator",
      ...props,
    });
    const toast = useToast();
    const route = useRoute();
    const content = useContentItems();
    const gigapayModule = usePayments();
    const { user, loadUser } = useAuth();
    const customToast = useToastModule();
    const { manager: { getUserAppliedCampaigns } } = useUsers();
    const creatorsController = useCreators(config.value.viewPerspective);
    
    const tabview = ref(0);
    const gigapayUser = ref();
    const applications = ref();
    const showPayment = ref(false);
    const closeModalRef = ref(false);    
    const mangopayObject = ref(user?.value?.mangopay);
    const currentPaymentInfo = ref(mangopayObject.value);

    gigapayModule.checkVerified().then((data) => {
      gigapayUser.value = data;
    });


    const loadAppliedCampaigns = () => getUserAppliedCampaigns(route.params.creatorID as string);

    creatorsController.manager.load(route.params.creatorID as string);
    loadAppliedCampaigns().then((data) => {
      applications.value = data;
    });

    content.list.loadMyLatest(user.value?.id as string, 6);

    const paymentInfoChange = async (payload: any) => {
      if (user.value) {
       const response =  await gigapayModule.updatePaymentData(user.value?.id, payload);
       if(response) {
          toast.add({ severity: 'success', summary: 'Success', detail: "Payment info updated successfully", life: 5000 });
          loadUser();
       }
    }

      showPayment.value = false;
      await creatorsController.manager.load(route.params.creatorID as string);

      gigapayModule.checkVerified().then((data) => {
        gigapayUser.value = data;
      });
    };

    const closeModal = () => {
      closeModalRef.value = true;
    };

    const alredyInvited = ref(false);
    const sendAgain = async () => {
      const resended = await gigapayModule.resendInvitation();
      alredyInvited.value = resended;
    };

    const notificationsForUser = ref<any[]>([]);
    const userId = ref();
    const notificationsMethods = useNotificationsMethods();

    if (user.value) {
      userId.value = user.value?.id;
    }

    const socket = useSocketIo(userId.value as string);
    const socketMethods = useSocketMethods(socket);

    const getMoreNotifications = async (firstLoad = false) => {
      if (!firstLoad) {
        // skip.value += 5;
        // limit.value += 5;
      }
      socketMethods.getNotificationsUser(5, 0);
      socket.on('notifications-data', (data: any) => {
        notificationsForUser.value = data.filter((el: any) => el.type === 'invite');
      });
    };
    getMoreNotifications();

    const loadChannels = async () => creatorsController.youtube.getCreatorChannels(userId.value, user.value?.type);

    watch(creatorsController.manager.creator, () => {
      if (creatorsController.manager.creator.value?.name) {
        config.value.title = creatorsController.manager.creator.value?.name;
        loadChannels();
      }
    });


    const popupError = (error:string) => {
      toast.add({ severity: 'error', summary: 'Error', detail: error, life: 5000 });
    }

    const openConnectPopup = (): void => {
      openSocialPopup(
        'youtube',
        { type: userId.value, action: 'add_channel_yt' },
        async (err?: Error | null, response?: SocialConnectResponse) => {
          if (err) {
            let errorMessage = err.message;
            if (err.message === 'popup-closed') {
              errorMessage = POP_CLOSED_MESSAGE;
              popupError(errorMessage);
            } else if (err.message === 'platformError') {
              popupError(response?.message as string);
            }
          } else if (response) {
            ctx.emit('success-add-channel');
            loadChannels();
          }
        }
      );
    };

    const handleContentRouting = (id: string) => {
      router.push({ name: 'content-item', params: { itemID: id } });
    }

    const agencyModule = useAgencys();

    const switchAccount = async () => {
      await agencyModule.manager.switchAccount().then(response => {
        if (response) {
          loadUser().then(response => {
            if (response) {
              if (response?.type === "agencyOwner" && !response?.agencyData?.name) {
                router.push({ name: 'creator-account-setup', params: { creatorID: response?.id } });
              }
              creatorsController.youtube.getCreatorChannels(response?.id, response?.type);
            }
          }).catch(error => {
            customToast.showError(error.message || "Something went wrong!");
          });
        }
      }).catch(error => {
        customToast.showError("Something went wrong!");
      });
    };

    const updateCreator = (payload: EditUserPayload) => {
      creatorsController.manager.putInfluencerInfo(userId.value, payload).then((success?: User) => {
        if (success) {
          window.location.reload();
          localStorage.removeItem('socialIn');
        }
      });
    };

    const handleAccountSetupRouting = () => {
      localStorage.setItem('isEditProfile', "true");
      router.push({ name: 'creator-account-setup', params: { creatorID: route.params.creatorID } });
    }

    return {
      paymentInfoChange,
      closeModal,
      sendAgain,
      alredyInvited,
      closeModalRef,
      config,
      creator: user,
      channels: creatorsController.youtube.getCreatorChannelsResult,
      user,
      applications,
      tabview,
      latestContent: content.list.loadMyLatestResult,
      showPayment,
      gigapayUser,
      isCreatorAccountEnabled,
      notificationsForUser,
      notificationsMethods,
      openConnectPopup,
      currentPaymentInfo,
      handleContentRouting,
      switchAccount,
      loadChannels,
      updateCreator,
      handleAccountSetupRouting,
      queryParams:route.query
    };
  },
};
