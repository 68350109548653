import React, { useEffect, useState } from 'react';

import { Image } from 'primereact/image';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';

import CustomLoader from '../Other/CustomLoader/CustomLoader';
import NewInviteToCampaign from '../NewCreatorProfile/NewInviteToCampaign/NewInviteToCampaign';
import NewContentListItem from "@/react_app/NewLatestItems/NewContentListItem/NewContentListItem";

import { UserType } from "@/data/types";
import { useAuth, useCommonApi, useCreators } from '@/modules';
import { formatNumber } from '@/utils/globals/numbers-utils';

import XIcon from "../img/x.png"
import KickIcon from "../img/kick.png";
import youtube from "../img/youtube.png";
import noImage from "../img/no-image.png";
import TwitchIcon from "../img/twitch.png";
import TikTokIcon from "../img/tiktok.png";
import DiscordIcon from "../img/discord.png";
import FacebookIcon from "../img/facebook.png";
import InstagramIcon from "../img/instagram.png";

import { CopyIconMenu, LanguageIcon, LocationIcon } from '../img/Icons';

import "./NewCreatorProfilePreview.css";

const GreenCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <circle cx="8" cy="8" r="8" fill="#4BBD07" />
    </svg>
  )
}

const channelPhotoTemplate = (rowData) => (
  <div className='channelItemWrap channelPhotoWrap'>
    <div className='channelPhoto'>
      <Image src={rowData?.snippet?.thumbnails?.default?.url || noImage} alt='profile photo' className='authorTemplateImage' />
    </div>
    <p className='channelInfoTextTitle'>
      {rowData?.snippet?.title || ""}
    </p>
  </div>
);

const channelSubscribersTemplate = (rowData) => (
  <div className={'channelItemWrap'}>
    <p className={'channelInfoText'}>{formatNumber(Number(rowData?.statistics?.subscriberCount) || 0)}</p>
  </div>
);

const channelTotalViewTemplate = (rowData) => (
  <div className={'channelItemWrap'}>
    <p className={'channelInfoText'}>{formatNumber(Number(rowData?.statistics?.viewCount) || 0)}</p>
  </div>
);

const channelTotalVideosTemplate = (rowData) => (
  <div className={'channelItemWrap'}>
    <p className={'channelInfoText'}>{formatNumber(Number(rowData?.statistics?.videoCount))}</p>
  </div>
);

function LinkFieldIcon(props) {
  return (
    <>
      {props.index === 0 && <Image src={youtube} alt={'youtube-icon'} width={'23px'} height={'23px'} />}
      {props.index === 1 && <Image src={InstagramIcon} alt={'instagram-icon'} width={'23px'} height={'23px'} />}
      {props.index === 2 && <Image src={TwitchIcon} alt={'twitch-icon'} width={'23px'} height={'23px'} />}
      {props.index === 3 && <Image src={KickIcon} alt={'kick-icon'} width={'23px'} height={'23px'} />}
      {props.index === 4 && <Image src={XIcon} alt={'x-icon'} width={'23px'} height={'23px'} />}
      {props.index === 5 && <Image src={FacebookIcon} alt={'facebook-icon'} width={'23px'} height={'23px'} />}
      {props.index === 6 && <Image src={TikTokIcon} alt={'tiktok-icon'} width={'23px'} height={'23px'} />}
      {props.index === 7 && <Image src={DiscordIcon} alt={'discord-icon'} width={'23px'} height={'23px'} />}
    </>
  )
}

export default function (props) {
  const user = useAuth();
  const creators = useCreators();
  const { type } = user.user.value;
  const { manager: { getMasterData } } = useCommonApi();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingStats, setIsLoadingStats] = useState(false);
  const [activeTab, setActiveTab] = useState("YouTube");
  const [showTooltip, setShowTooltip] = useState(false);
  const [languageList, setLanguageList] = useState([]);
  const [platformOptions, setPlatformOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [contentCategoryOptions, setContentCategoryOptions] = useState([]);
  const [userDetails, setUserDetails] = useState({
    creatorName: "",
    creatorPhoto: "",
    firstName: "",
    lastName: "",
    email: "",
    creatorDescription: "",
    creatorAddress: "",
    dateOfBirth: "",
    countries: "",
    type: "",
    content: {
      contentItems: [],
      totalResults: 0
    },
    categories: [],
    languages: [],
    isUGCCreator: false,
    platforms: platformOptions,
    mangopay: ""
  });

  const [youtubeStats, setYoutubeStats] = useState({});
  const [inviteDialog, setInviteDialog] = useState(false);

  useEffect(() => {
    if (props?.creator) {

      const updatedPlatforms = platformOptions.map((platform) => {
        const matchingPlatform = (props?.creator?.platforms || []).find(
          (response) => response.platformId === platform.platformId
        );

        if (matchingPlatform) {
          return { ...platform, value: matchingPlatform.value };
        }

        return platform;
      });

      const updatedLanguage = languageList.filter((language) => {
        const matchingLanguages = (props?.creator?.languages || []).find(
          (response) => response === language.id
        );
        return matchingLanguages;
      });

      const updatedCategories = contentCategoryOptions.filter((category) => {
        const matchingCategories = (props?.creator?.categories || []).find(
          (response) => response === category.id
        );
        return matchingCategories;
      });

      const updatedCountries = countryOptions.find((country) => {
        return country.value === props?.creator?.countries;
      });

      const updatedDetails = {
        creatorName: props?.creator?.name || "",
        creatorDescription: "",
        creatorAddress: props?.creator?.creatorAddress,
        creatorPhoto: "",
        firstName: "",
        lastName: "",
        email: props?.creator?.email || "",
        dateOfBirth: props?.creator?.dateOfBirth || "",
        countries: updatedCountries,
        categories: updatedCategories,
        languages: updatedLanguage,
        isUGCCreator: props.creator.isUGCCreator,
        platforms: updatedPlatforms,
        mangopay: props.creator?.mangopay
      }

      if (props?.creator?.type === UserType.Creator) {
        updatedDetails.creatorDescription = props?.creator?.profile?.description || "";
        updatedDetails.creatorPhoto = props?.creator?.profile?.photo || "";
        updatedDetails.creatorName = props?.creator?.name || "";
      } else if (props?.creator?.type === UserType.Agency) {
        updatedDetails.creatorDescription = props?.creator?.agencyData?.description || "";
        updatedDetails.creatorPhoto = props?.creator?.agencyData?.photo || "";
        updatedDetails.creatorName = props?.creator?.agencyData?.name || "";
      }
      updatedDetails.firstName = props?.creator?.firstName || ""
      updatedDetails.lastName = props?.creator?.lastName || ""
      setUserDetails(updatedDetails);
    }


  }, [props]);

  useEffect(() => {
    if (platformOptions.length <= 0) {
      getOptionsList();
    }
  }, [])

  const getOptionsList = () => {
    setIsLoading(true);
    getMasterData('').then((res) => {
      if (res) {
        setIsLoading(false);
        setLanguageList(res.data.languages.map(language => {
          return {
            value: language.id, name: language.name, id: language.id, code: language.code, isActive: language.isActive
          }
        }));
        setPlatformOptions(res.data.platforms.map(platform => {
          return {
            platform: platform.name, value: [""], name: platform.name, platformId: platform.id, isActive: platform.isActive
          }
        }));
        setContentCategoryOptions(res.data.categories
          .map(category => {
            return {
              value: category.id, name: category.name, id: category.id, isActive: category.isActive
            }
          }));
        setCountryOptions(res.data.countries.map(country => {
          return {
            value: country.id, name: country.name, id: country.id, code: country.code
          }
        }));
      } else {
        setLanguageList([]);
        setPlatformOptions([]);
        setContentCategoryOptions([]);
      }
    }).catch(error => {
      setIsLoading(false);
    });
  }

  const getCreatorYoutubeStats = async (creatorID, channelID) => {
    setIsLoadingStats(true);
    await creators.manager.getCreatorYoutubeStats(creatorID, channelID)
      .then(res => {
        if (res) {
          setYoutubeStats((prevStats) => ({
            ...prevStats,
            [channelID]: res.data
          }));
          setIsLoadingStats(false);
        }
      }).catch(error => {
        if (error) {
          console.log('error', error.message || "Something went wrong.");
          setIsLoadingStats(false);
        }
      });
  }

  const handleTabChange = (item) => {
    setActiveTab(item.name);
  };

  const tableData = (activeTab) => {
    if (activeTab == "YouTube") {
      return props.channelsYT?.filter(channel => channel !== null)
    } else {
      return userDetails.platforms.find(platform => platform.platform == activeTab)?.value;
    }
  }

  const handleCopyPostBackURL = (link) => {
    navigator.clipboard.writeText(link)
      .then(() => {
        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };

  const channelLinksTemplate = (link) => {
    return (
      <>
        {link &&
          <div className={'channelItemWrap link-wrap'}>
            <p className={'channelInfoText'}>
              <Button label={link || "Link"} className='link-btn' link onClick={() => window.open(link, '_blank')} />
            </p>
            <Button className='copy-url-btn' onClick={() => handleCopyPostBackURL(link)} >
              <CopyIconMenu scale={0.8} color={link ? "#fff" : "gray"} />
            </Button>
          </div>
        }
      </>
    );
  }

  const channelTotalLikesTemplate = (rowData) => (
    <div className={'channelItemWrap'}>
      {youtubeStats[rowData.id] ? <p className={'channelInfoText'}>{formatNumber(Number(youtubeStats[rowData.id]?.totalLikes) || 0)}</p> : null}
    </div>
  );


  const channelTotalCommentsTemplate = (rowData) => (
    <div className={'channelItemWrap'}>
      {youtubeStats[rowData.id] ? <p className={'channelInfoText'}>{formatNumber(Number(youtubeStats[rowData.id]?.totalComments) || 0)}</p> : null}
    </div>
  );

  const channelUploadFrequencyTemplate = (rowData) => (
    <div className={`channelItemWrap upload-frequency`}>
      {youtubeStats[rowData.id] ?
        <>
          <p className={'channelInfoText'}> <span className={'channelInfoLabel'}>Last 30 Days</span> : {formatNumber(Number(youtubeStats[rowData.id].frequency30Days) || 0)}</p>
          <p className={'channelInfoText'}> <span className={'channelInfoLabel'}>Last 60 Days</span> : {formatNumber(Number(youtubeStats[rowData.id].frequency60Days) || 0)}</p>
          <p className={'channelInfoText'}><span className={'channelInfoLabel'}>Last 90 Days</span> : {formatNumber(Number(youtubeStats[rowData.id].frequency90Days) || 0)}</p>
        </> : <Button label="Get Stats" className={`addChannelButton getYoutubeStatsButton  ${youtubeStats[rowData.id] ? 'has-stats' : 'no-stats'}`} onClick={() => {
          getCreatorYoutubeStats(props?.creator?.id, rowData.id)
        }}
        />
      }
    </div>
  );

  return (
    <div className='creator-profile-preview'>
      {isLoading ? <CustomLoader /> :
        <>
          <div className='firstLayer'>
            <div className='darkSquare'>
              <Image className='userPhoto' alt='user photo' src={userDetails?.creatorPhoto || noImage} />
              <div className='layer'>
                <GreenCircle />
                <p className='userName'>{userDetails?.creatorName}</p>
              </div>
              <p className='userType'>{userDetails?.type === UserType.Agency ? "Agency" : "Influencer"}</p>
              {type !== 'brandOwner' &&
                <div className='emailWrapper'>
                  <div className='layer'>
                    <p className='emailTitle'>Active email:</p>
                    <p className='emailValue' onClick={() => window.location = `mailto:${userDetails?.email}`}>{userDetails?.email}</p>
                  </div>
                  {userDetails?.mangopay?.Email &&
                    <div className='layer'>
                      <p className='emailTitle'>Gigapay email:</p>
                      <p className='emailValue' onClick={() => window.location = `mailto:${userDetails?.mangopay?.Email}`}>{userDetails?.mangopay?.Email}</p>
                    </div>}
                </div>
              }
              {!props.isCreator && <Button className={'inviteButton addChannelButton'} onClick={() => setInviteDialog(true)}>Invite to campaign</Button>}
              <ul className={`content-categories ${userDetails?.categories?.length <= 2 ? 'less' : 'more'}`}>
                {userDetails?.categories?.map(category => <li className='category'>{`#${category?.name}`}</li>)}
              </ul>
              {userDetails?.isUGCCreator && <p className={'sixteen-plus'}>UGC</p>}
            </div>
            <div className='about-wrap'>
              <p className={'aboutLabel'}>About</p>
              <div className={'aboutContent'}>
                {userDetails?.creatorDescription || "N/A"}
              </div>
              <div className='location-language-wrap'>
                <p className='country'><LocationIcon />{userDetails?.countries?.name || 'N/A'}</p>
                <p className='language'><LanguageIcon />{userDetails?.languages?.map(language => language?.name).join(",") || 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className={'channels'}>
            <p className={'channelsTitle'}>Channels</p>
            <div className='tabs-wrapper'>
              <div className='tabs'>
                {platformOptions.map((option, index) => (
                  <div key={index} className={`tab ${option.name === activeTab ? 'selected' : 'normal'}`} onClick={() => handleTabChange(option)}>
                    <LinkFieldIcon index={index} />
                    <p className='label'>{option.name === "Discord Server" ? "Discord" : option.name}</p>
                  </div>))}
              </div>
            </div>
            <div>
              <DataTable value={tableData(activeTab)}
                loading={isLoading || isLoadingStats}
                loadingIcon={
                  <div className={'loading-wrap'}>
                    <span className="loader wrap"></span>
                  </div>
                }
                emptyMessage="No data found."
              >
                {activeTab == "YouTube" && <Column field="brand" header="" body={channelPhotoTemplate} style={{ width: '10%' }} ></Column>}
                {activeTab == "YouTube" &&
                  <Column field="subscribers" header="Subscribers" body={channelSubscribersTemplate} style={{ width: '7%' }} align="center" ></Column>}
                {activeTab == "YouTube" &&
                  <Column field="totalViews" header="Total Views" body={channelTotalViewTemplate} style={{ width: '7%' }} align="center"></Column>}
                {activeTab == "YouTube" &&
                  <Column field="totalVideos" header="Total Videos" body={channelTotalVideosTemplate} style={{ width: '7%' }} align="center"></Column>}
                {activeTab === "YouTube" &&
                  <Column
                    field="totalLikes"
                    header="Total Likes"
                    body={channelTotalLikesTemplate}
                    style={{ width: '7%' }}
                    align="center"
                  />}
                {activeTab === "YouTube" &&
                  <Column
                    field="totalComments"
                    header="Total Comments"
                    body={channelTotalCommentsTemplate}
                    style={{ width: '7%' }}
                    align="center"
                  />}
                {activeTab === "YouTube" &&
                  <Column
                    field="uploadFrequency"
                    header="Upload Frequency"
                    body={channelUploadFrequencyTemplate}
                    style={{ width: '7%' }}
                    align="center"
                  />}
                {activeTab == "Twitch" &&
                  <Column field="Links" header="Links" body={channelLinksTemplate} style={{ width: '7%' }} align="left"></Column>}
                {activeTab == "TikTok" &&
                  <Column field="Links" header="Links" body={channelLinksTemplate} style={{ width: '7%' }} align="left"></Column>}
                {activeTab == "Instagram" &&
                  <Column field="Links" header="Links" body={channelLinksTemplate} style={{ width: '7%' }} align="left"></Column>}
                {activeTab == "Facebook" &&
                  <Column field="Links" header="Links" body={channelLinksTemplate} style={{ width: '7%' }} align="left"></Column>}
                {activeTab == "Kick" &&
                  <Column field="Links" header="Links" body={channelLinksTemplate} style={{ width: '7%' }} align="left"></Column>}
                {activeTab == "X" &&
                  <Column field="Links" header="Links" body={channelLinksTemplate} style={{ width: '7%' }} align="left"></Column>}
                {activeTab == "Discord Server" &&
                  <Column field="Links" header="Links" body={channelLinksTemplate} style={{ width: '7%' }} align="left"></Column>}
              </DataTable>
            </div>
          </div>
          {props.content?.contentItems?.length > 0 &&
            <div className={'contentItemsWrapper'}>
              <p className={'contentLabel'}>Content <br />
              </p>
              <div className={'contentItems'}>
                {props.content?.contentItems?.map((item, index) =>
                  <>
                    {item.campaignId?.paymentMethod !== "UGC" &&
                      <div key={index} className={'contentItemWrapper'}>
                        <NewContentListItem item={item} creator={userDetails} handleRouting={() => props.handleContentRouting(item.id)} />
                      </div>
                    }
                  </>)}
              </div>
            </div>}
          {!props.isCreator &&
            <NewInviteToCampaign visible={inviteDialog} onHide={() => setInviteDialog(false)} creator={props.creator}
              campaignOptions={props.campaignOptions} />
          }
        </>
      }
    </div>
  )
}
