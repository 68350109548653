export const compactNumber: any = (n: number, fraction?: number) => {
  if (n) {
    return Intl.NumberFormat('es-US', {
      notation: 'compact',
      maximumFractionDigits: fraction || 1,
    })
      .format(n)
      .replace(/\s/g, '')
      .toLocaleLowerCase();
  } else return 0;
};

export const formatSubsCount = (value: number) => {
  if (value) {
    if (value < 1000) {
      return value.toString(); // No formatting needed for numbers below 1000
    } else if (value < 1000000) {
      // Format numbers in thousands (K)
      return (value / 1000).toFixed(value % 1000 === 0 ? 0 : 1) + 'K';
    } else if (value < 1000000000) {
      // Format numbers in millions (m)
      return (value / 1000000).toFixed(value % 1000000 === 0 ? 0 : 2) + 'm';
    } else if (value < 1000000000000) {
      // Format numbers in billions (B)
      return (
        (value / 1000000000).toFixed(value % 1000000000 === 0 ? 0 : 2) + 'B'
      );
    } else {
      // Format numbers in trillions (T)
      return (
        (value / 1000000000000).toFixed(value % 1000000000000 === 0 ? 0 : 2) +
        'T'
      );
    }
  }else{ 
    return 0
  }
};
