import React, { memo } from 'react';

export const ClientMaxCap = memo(({ amount }) => {

  return (
    <div className='budgetText'>
      <p className='budgetLabel'>Client Max Cap</p>
      <p className='budgetValue'>${(amount).toFixed(2)}</p>
    </div>
  );
});

export default ClientMaxCap;
