import React, { useEffect, useState } from 'react'

import { Image } from 'primereact/image';
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

import HeaderTooltip from '@/react_app/components/CustomToolTip/HeaderTooltip';
import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';

import noImage from "@/react_app/img/no-image.png";
import { ExpandArrowDown, ExpandArrowUp } from '@/react_app/img/Icons';

import { useAuth } from '@/modules';
import { CampaignPaymentMethod } from '@/data/types';
import { calculateCommission } from '@/utils/globals/numbers-utils';
import { useCreatorManager } from '@/modules/creators/creator-manager-module';

import "./NewInfluencerEarnings.css";

const creatorTemplate = (data) => {
  return (
    <div className="creatorTemplate">
      <Image src={data.photo || noImage} alt="creator photo" className="creatorPhoto"></Image>
      <p className="creatorName">{data.name}</p>
    </div>
  )
};
const creatorEmailTemplate = (data) => (
  <div className="p-d-flex creatorName">
    <span className="link" onClick={() => window.location = `mailto:${data?.email}`}>{data?.email || "-"}</span>
  </div>
);
const totalEarningsTemplate = (data) => (
  <div className="defaultTemplate">
    ${data.totalEarningsForCreator?.toFixed(2) || 0}
  </div>
);
const totalPaidTemplate = (data) => (
  <div className="defaultTemplate">
    ${data.totalPaidForCreator.toFixed(2) || 0}
  </div>
);
const eligibleForPayoutTemplate = (data) => (
  <div className="defaultTemplate">
    ${data.amountEarnedThisCycleForCreator?.toFixed(2) || 0}
  </div>
);
const gigaPayTemplate = (data) => (
  <div className="defaultTemplate">
    {data.gigapayStatus ? 'Yes' : 'No'}
  </div>
);

const nameTemplate = (row) => (
  <div className="p-d-flex creatorTemplate">
    <Image src={row.campaignPhoto} alt="creator photo" className="creatorPhoto"></Image>
    <span className='creatorName'>{row?.campaignName}</span>
  </div>
)
const campaignTypeTemplate = (row) => (
  <div className="p-d-flex contentTitle">
    {row?.campaignPaymentMethod || "-"}
  </div>
);
const campaignEarnedTemplate = (row) => (
  <div className="p-d-flex p-flex-column defaultTemplate">
    <span className="amount">{calculateCommission(row?.earned, row?.appliedFee)} USD</span>
    {row?.campaignPaymentMethod === CampaignPaymentMethod.CPM ? <span>Views: {row?.earnedStats?.views?.toLocaleString() || 0}</span>
      : <div className="p-d-flex p-flex-column">
        <span>Android: {row?.earnedStats?.android || 0}</span>
        <span>IOS: {row?.earnedStats?.ios}</span>
        <span>Clicks: {row?.earnedStats?.clicks}</span>
      </div>}
  </div>
);
const campaignPaidTemplate = (row) => (
  <div className="p-d-flex p-flex-column defaultTemplate">
    <span className="contentTitle">{calculateCommission(row?.paid, row?.appliedFee)} USD</span>
    {row?.campaignPaymentMethod === CampaignPaymentMethod.CPM ? <span>Views: {row?.paidFor?.views?.toLocaleString() || 0}</span>
      : <div className="p-d-flex p-flex-column">
        <span>Android: {row?.paidStats?.android || 0}</span>
        <span>IOS: {row?.paidStats?.ios || 0}</span>
        <span>Clicks: {row?.paidStats?.clicks || 0}</span>
      </div>}
  </div>
);


export default function NewInfluencerEarnings() {

  const user = useAuth();
  const manager = useCreatorManager();
  const [loader, setLoader] = useState(true);
  const [earningList, setEarningList] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const tooltipOptions = { position: 'top', style: { display: 'inline-block' } };

  const getInfluencerEarnings = () => {
    manager.getInfluencerEarning(10, 0, user.user.value.id).then(response => {
      if (response) {
        const earnings = response.creatorEarnings || [];
        setEarningList(earnings);
        setExpandedRows(earnings); // Set all rows to expanded by default
      }
      setLoader(false);
    });

  };
  useEffect(() => {
    getInfluencerEarnings();
  }, [])

  const expanderTemplate = (rowData) => (
    <div className='pointer'>
      {expandedRows.includes(rowData) ?
        <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
          <ExpandArrowUp />
        </div> :
        <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
          <ExpandArrowDown />
        </div>
      }
    </div>
  );
  const channelTemplate = (row, rowData) => {
    const channel = row?.channelId
      ? rowData?.agencyWorkers?.find(ch => ch.channel?.channelId === row.channelId)
      : null;

    const channelUrl = channel?.channel?.channelId
      ? `https://www.youtube.com/channel/${channel.channel.channelId}`
      : null;

    const channelTitle = row?.channelId
      ? channel?.channel?.title
      : rowData?.youtube?.slice(-1)?.[0]?.name || '-';

    return (
      <div className='channelTemplate'
        onClick={() => channelUrl && window.open(channelUrl, "_blank")}
        style={{ cursor: channelUrl ? 'pointer' : 'default' }}
      >
        {channelTitle}
      </div>
    );
  };
  const rowExpansionTemplate = (rowData) => (
    <DataTable value={rowData?.links} className="w-100">
      <Column field="campaignName" header="Campaign" body={nameTemplate} style={{ width: '20%' }} />
      <Column field="channel" header="Channel" body={(data) => channelTemplate(data, rowData)} style={{ width: '15%' }} />
      <Column field="campaignPaymentMethod" header="Campaign Type" style={{ width: '20%' }} body={campaignTypeTemplate} />
      <Column field="earned" body={campaignEarnedTemplate} style={{ width: '10%' }} header={<HeaderTooltip title="Earned" />}
        headerTooltip="The amount that has already been settled." headerTooltipOptions={tooltipOptions} />
      <Column field="paid" body={campaignPaidTemplate} style={{ width: '10%' }} header="Paid"
        headerTooltip="The amount that has already been settled." headerTooltipOptions={tooltipOptions} />
    </DataTable>
  );

  return (
    <div className="influencer-earning">
      <DataTable value={earningList} emptyMessage="No earning found." loading={loader} expandedRows={expandedRows}
        loadingIcon={<CustomLoader />} style={{ minHeight: 500 }} rowExpansionTemplate={rowExpansionTemplate}>
        <Column expander={true} body={expanderTemplate} style={{ width: '5%' }} />
        <Column field="name" header="Influencer" body={creatorTemplate} style={{ width: '20%' }} />
        <Column field="email" header="Email" body={creatorEmailTemplate} style={{ width: '20%' }} />
        <Column field="totalEarnings" body={totalEarningsTemplate} style={{ width: '15%', cursor: 'pointer' }}
          headerTooltip='Amount (Earned+Paid)' headerTooltipOptions={tooltipOptions} header={<HeaderTooltip title="Total Earnings" />} />
        <Column field="totalPaid" header={<HeaderTooltip title="Paid out" />} body={totalPaidTemplate} style={{ width: '10%', cursor: 'pointer' }}
          headerTooltip="Amount paid in last month payout" headerTooltipOptions={tooltipOptions} />
        <Column field="amountEarnedThisCycle" header={<HeaderTooltip title="Eligible for Payout" />} body={eligibleForPayoutTemplate} style={{ width: '20%', cursor: 'pointer' }}
          headerTooltip='Amount to be paid in next month payout cycle if eligible' headerTooltipOptions={tooltipOptions} />
        <Column field="gigapayStatus" header="On Gigapay" body={gigaPayTemplate} />
      </DataTable>
    </div>
  )
}