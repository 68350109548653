import React from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { CampaignPaymentMethod } from "@/data/types";
import { calculateCpiRate } from "@/utils/globals/cpi-rate";
import { formatDate } from "@/utils/globals/date-utils";

import { CopyIconSmall } from '@/react_app/img/Icons';

import './VideoReason.css';

const CampaignDetails = (props) => {

    const { reasonData, reason, goToCampaign, loading } = props;

    const copyLink = (url) => {
        navigator.clipboard.writeText(url);
    };

    const renderImage = (photo, defaultPhoto) => (
        <img className="brand-pic" src={photo || defaultPhoto} alt="Campaign" />
    );

    return (
        <div className="videoInfo">
            {loading ?
                <div className='loading-wrap'><span className="loader wrap"></span></div>
                :
                <>
                    {reasonData ?
                        <>
                            <div className="p-d-flex p-justify-between submission-details">
                                <div>
                                    <h6>Campaign</h6>
                                    <div className="p-d-flex">
                                        <div>{renderImage(reasonData?.campaign?.photo, "")}</div>
                                        <div className="p-flex-column p-ml-4">
                                            <h4 className="campaign-name">{reasonData?.campaign?.name}</h4>
                                            <div className="p-d-flex">
                                                <div>
                                                    <img className="brandPhoto" src={reasonData?.campaign?.brand?.photo} alt="Brand" />
                                                </div>
                                                <div className="p-ml-2 p-mt-2 brandName">
                                                    {reasonData?.campaign?.brand?.name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Conditional Campaign Details */}
                                {["CPI", "CPC", "CPM"].map((paymentType) =>
                                    reasonData?.campaign?.paymentMethod === CampaignPaymentMethod[paymentType] ? (
                                        <div key={paymentType} className="p-flex-column adaptive-column">
                                            <h6>Campaign {paymentType}</h6>
                                            <div className="p-mt-lg-6 p-mt-md-2">
                                                <span className="default-text">
                                                    {calculateCpiRate(
                                                        reasonData?.campaign?.[paymentType.toLowerCase()]?.amount,
                                                        reasonData?.campaign?.appliedFee
                                                    )}{" "}
                                                    {reasonData?.campaign?.budget?.currency}
                                                </span>
                                                {paymentType === "CPM" && (
                                                    <>
                                                        <br />
                                                        <span className="default-text">
                                                            Payout Cap:{" "}
                                                            {calculateCpiRate(
                                                                reasonData?.campaign?.cpm?.payoutCap,
                                                                reasonData?.campaign?.appliedFee
                                                            )}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ) : null)}

                                <div className="p-flex-column adaptive-column">
                                    <h6>Campaign Period</h6>
                                    <div className="p-mt-lg-6 p-mt-md-2">
                                        <span className="default-text">
                                            {formatDate(reasonData?.campaign?.dates?.startDate)} -{" "}
                                            {formatDate(reasonData?.campaign?.dates?.endDate) || "Until Budget is Complete"}
                                        </span>
                                    </div>
                                </div>

                                <div className="p-flex-column" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                    <div className="p-mt-4">
                                        <span className="red-text" onClick={() => goToCampaign(reasonData?.campaign?.id)}>
                                            View campaign details
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="p-d-flex p-justify-between p-col-12 p-mt-6">
                                <h2 style={{ color: "#FFF" }}>Your Participation</h2>
                            </div>
                            <DataTable value={[reasonData?.contentItem]} responsiveLayout="scroll" className="p-mt-2 data-table">
                                <Column header="CONTENT" body={(campaign) => (
                                    <section className="p-d-flex main-name-block">
                                        <img src={campaign?.thumbnails?.default?.url} alt="Content" />
                                    </section>
                                )} />
                                <Column
                                    field="content link"
                                    header="CONTENT LINK"
                                    body={(campaign) => (
                                        <div className="link p-d-flex">
                                            <a className="link-bold eclipsis" target="_blank" rel="noopener noreferrer"
                                                href={`https://www.youtube.com/watch?v=${campaign?.vid}`}>
                                                {`https://www.youtube.com/watch?v=${campaign?.vid}`}
                                            </a>
                                            <div className="p-ml-2" style={{ cursor: "pointer" }} onClick={() => copyLink(`https://www.youtube.com/watch?v=${campaign?.vid}`)}>
                                                <CopyIconSmall />
                                            </div>
                                        </div>
                                    )}
                                />
                            </DataTable>

                            <div className="p-d-flex p-justify-between p-col-12 p-mt-6">
                                <h2 style={{ color: "#FFF" }}>Comment</h2>
                                <p style={{ color: "#FFF" }}>{reason}</p>
                            </div>
                        </>
                        :
                        <div className="videoInfo">
                            <div className="p-d-flex p-justify-between submission-details">
                                <h4 className="campaign-name">No details found.</h4>
                            </div>
                        </div>
                    }
                </>
            }
        </div>

    );
};

export default CampaignDetails;
