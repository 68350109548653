import React, { useEffect, useRef, useState } from 'react';
import "./NewLoginSocials.css";
import youtube from "../img/youtube.png"
import google from "../img/google.png"
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { openSocialPopup } from '@/utils/globals/popup-utils';
import { UserType } from "@/data/types";
import { POP_CLOSED_MESSAGE } from '@/utils/globals/constants';

export default function (props) {
  const { userType } = props;

  const [connectingPlatform, setConnectingPlatform] = useState('');
  const toast = useRef(null);

  useEffect(() => {
    setConnectingPlatform(props.connecting || '');
  }, [props.connecting]);

  const popupError = (error) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: error, life: 3000 });
  }

  const platformDisabled = (item) => {
    const localUserType = localStorage.getItem('login-view-Type');
    
    if (connectingPlatform || props.disabled || item.disabled) {
      return true;
    }
    if (props.action === 'login' && localUserType) {
      return item.forUser !== localUserType
    }

    if (props.action === 'login') {
      return false;
    }

    return item.forUser !== userType;
  };

  const openConnectPopup = (network) => {
    setConnectingPlatform(network);

    openSocialPopup(
      network,
      { type: props.userType, action: props.action, name: props.username },
      async (err, response) => {
        if (err) {
          let errorMessage = err.message;
          if (err.message === 'popup-closed') {
            errorMessage = POP_CLOSED_MESSAGE;
            popupError(errorMessage);
          } else if (err.message === 'platformError') {
            popupError(response.message);
          }

          setConnectingPlatform('');
        } else {
          if (response && response.source && response.source === 'vue-devtools-backend') {
            return;
          }

          if (!response) {
            console.warn('Login :: no error, but user is missing!');
            return;
          }

          props.onConnect(response, props.action, network, props.userType);
        }
      }
    );
  };

  return (
    <div className='login-socials'>
      <Button className={'socialsSignIn'} onClick={() => openConnectPopup('google')}>
        <img alt="google-logo" src={google} className='socialsSignInIcon' />{''}
        Sign in with Google
      </Button>
      {['creator', 'influencer'].includes(userType) &&
        <Button className='socialsSignIn' onClick={() => openConnectPopup('youtube')}>
          <img alt="youtube-logo" src={youtube} className='socialsSignInIcon' />{''}
          Sign in with Youtube
        </Button>
      }
      <Toast ref={toast} position="top-center" />
    </div>
  )
}
